<!-- question area -->
<p-card>
  <h6 class="m-0 fs-6 fw-medium">Q: What is procedure of admission?</h6>
  <div
    class="d-flex question-time-text flex-wrap gap-4 mt-2 pb-3 border-bottom"
  >
    <p class="d-flex gap-2 m-0">
      <span>Asked</span>
      <span class="text-muted">6 years, 8 months ago</span>
    </p>
    <p class="d-flex gap-2 m-0">
      <span>Modified</span>
      <span class="text-muted">2 years, 6 months ago</span>
    </p>
    <p class="d-flex gap-2 m-0">
      <span>Viewed</span>
      <span class="text-muted">4k times</span>
    </p>
  </div>
  <p class="question-extended mt-3 mb-0">
    What are the steps to apply for admission in Computer Science? Lorem ipsum
    dolor sit amet consectetur adipisicing elit. Architecto, rem! Odio commodi
    quas qui veritatis hic ullam praesentium quaerat cupiditate sequi,
    perspiciatis neque odit eos nesciunt repudiandae enim illum amet?
  </p>
</p-card>
<!-- End: question area -->

<!-- answer area -->
<h6 class="m-0 fs-6 fw-medium mt-4 mb-3">6 Answers</h6>
<p-card class="answer-card">
  <div class="answers-wrapper d-flex flex-column gap-3">
    <p class="text-muted" *ngFor="let answer of [1, 1, 1, 1, 1, 1]">
      A: Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi
      magni laudantium a quidem, illum omnis, sit totam iusto eligendi possimus
      dignissimos ea soluta delectus. Doloremque ab fuga eius id maxime amet
      illo delectus saepe corrupti ducimus culpa, maiores debitis aperiam.
    </p>
  </div>
</p-card>
<!--End: answer area -->
