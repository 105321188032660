import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClient } from '@angular/common/http'; // Import HttpClient
import { CommonServiceService } from '../../common-service.service';

@Component({
  selector: 'app-custom-file-upload',
  templateUrl: './custom-file-upload.component.html',
  styleUrls: ['./custom-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFileUploadComponent),
      multi: true,
    },
  ],
})
export class CustomFileUploadComponent implements ControlValueAccessor {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Input() imageUrl: string | ArrayBuffer | null = null;
  @Input() isButtonsShown: boolean;

  @Input() height: number = 145;
  @Input() width: number = 250;
  @Input() defaultPlaceholder: string = 'assets/drag-n-drop.svg';
  @Input() ngModel: any;

  @Input() isDirectUpload: boolean = false; // New Input
  @Input() directUploadEndpoint: string; // New Input

  @Output() ngModelChange = new EventEmitter<any>();
  @Output() removeFile: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() uploadResponse = new EventEmitter<any>(); // New Output

  onChange: any = () => {};
  onTouched: any = () => {};
  inputId = '';

  constructor(private commonService: CommonServiceService) {}
  ngOnInit(): void {
    this.inputId = `file-input-${Math.floor(Math.random() * 1000000)}`;
  }

  writeValue(obj: any): void {
    this.imageUrl = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // this.isButtonsShown = !isDisabled;
  }

  onFileSelected(event: Event): void {
    console.log(event);
    const file = (event.target as HTMLInputElement).files![0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = reader.result;
        this.onChange(file);
        this.ngModelChange.emit(file);
        this.onTouched();
      };
      reader.readAsDataURL(file);

      if (this.isDirectUpload && this.directUploadEndpoint) {
        const formData = new FormData();
        formData.append('files', file);
        const finalObj = {
          isCategorList: true,
          name: formData,
        };

        this.commonService
          .saveRecord(this.directUploadEndpoint, finalObj)
          .subscribe(
            (response) => {
              this.commonService.showToast(
                'Image uploaded successfully',
                'success',
                3000
              );
              this.uploadResponse.emit(response);
            },
            (error) => {
              this.commonService.showToast(
                error.error?.error ?? 'Upload failed',
                'error',
                3000
              );
            }
          );
      }
    }
  }

  openFileSelector(): void {
    this.fileInput.nativeElement.click();
  }

  removeImage(): void {
    console.log(this.imageUrl);
    this.imageUrl = null;
    this.removeFile.emit(true);
    this.ngModelChange.emit(this.imageUrl);
    this.onChange(this.imageUrl);
  }
}
