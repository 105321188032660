import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router, RouterLinkActive } from '@angular/router';
import { map, Subscription } from 'rxjs';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { MastersService } from 'src/app/shared/masters.service';

import { MenuItem } from 'primeng/api';
import { fromEvent } from 'rxjs';
import { judgesCompetition } from '../models/students.interface';
@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss'],
})
export class HeadersComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isAdmin: boolean = false;
  @Input() isStudentProfile: boolean = true;
  @Input() isJudgesPortal: boolean = false;
  @Input() selectedCompetitionId: number;

  @Output() judgesCompetition: EventEmitter<judgesCompetition> =
    new EventEmitter<judgesCompetition>();

  @HostListener('window:resize', ['$event'])
  checkDeviceType(): void {
    const isMobile = window.innerWidth <= 768;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.setHeaderItems(this.isLoggedIn);
  }

  // Array type
  items: any[] = [];

  // Other types
  UK_IMAGE: any;
  docReviewDialog: any;
  notifications: any;

  // Boolean type
  signInPopup: boolean | undefined;
  notificationFlag: boolean = false;
  isSubOpen: boolean = false;

  // Number type
  subOpenBuffer: number = 0;

  // Object type
  userData: any;

  // Subscription types
  sub: Subscription;
  sub2: Subscription;

  deviceType: string;
  isMobileView: boolean;
  isLoggedIn: boolean;
  // Add a blank line to separate different types
  tabsItems: MenuItem[];

  activeTab: number;
  public judgesCompetitionsList: any[] = [];
  public selectedCompetition: judgesCompetition;

  constructor(
    private commonService: CommonServiceService,
    private router: Router,
    public mastersService: MastersService,
  ) {
    this.checkDeviceType();
  }

  ngOnInit(): void {
    this.sub = this.commonService.$curUser.subscribe((item) => {
      if (item) {
        if (typeof item == 'string') {
          this.userData = JSON.parse(item);
        } else {
          this.userData = item;
        }
        this.setHeaderItems(true);
        this.isLoggedIn = true;
      } else {
        this.setHeaderItems();
        this.isLoggedIn = false;
      }
    });
    this.UK_IMAGE = './assets/icons/uk.svg';

    this.commonService.readMessages$.subscribe((item) => {
      this.commonService.$curUser.subscribe((item2) => {
        this.userData = item2;
      });
    });

    this.tabsItems = [
      {
        label: 'Guidance',
        routerLink: '/student/admission/US/dashboard',
        RouterLinkActive,
      },
      {
        label: 'Competition',
        routerLink: '/student/competitions/startup',
        RouterLinkActive,
      },
    ];
    if (this.router.url.split('/').includes('competitions')) {
      this.activeTab = 1;
    } else {
      this.activeTab = 0;
    }

    if (this.isJudgesPortal) {
      this.getJudgesCompetitions();
      console.log(this.selectedCompetitionId);
      // if (this.router.url == '/judges/submissions') {
      //   this.judgeUtilService.setCallJudgesComponentForEmptyQueryParams(true);
      // }else{
      //   this.judgeUtilService.setCallJudgesComponentForEmptyQueryParams(false);
      // }
    }
  }

  handleNav(route: any) {
    this.router.navigate([this.tabsItems[route.index].routerLink]);
  }

  ngAfterViewInit() {
    fromEvent(window, 'click').subscribe((item: any) => {
      if (this.notificationFlag && item?.clientY > 70) {
        this.notificationFlag = false;
      }
    });
  }

  getJudgesCompetitions() {
    this.commonService
      .getList('judges/competitions')
      .pipe(
        map((res) => {
          return res?.data?.mycompetitions?.map((competition: any) => ({
            id: competition.id,
            name: competition.name,
          }));
        })
      )
      .subscribe(
        (filteredCompetitions) => {
          this.judgesCompetitionsList = filteredCompetitions;
          this.selectedCompetition = this.judgesCompetitionsList?.find(
            (competition) => competition.id === +this.selectedCompetitionId
          );
          if (
            this.judgesCompetitionsList &&
            this.judgesCompetitionsList.length
          ) {
            this.selectedCompetition = this.judgesCompetitionsList[0];
            this.onJudgesCompetitionChange(this.judgesCompetitionsList[0]);
          }
        },
        (error) => {
          console.error('Error fetching competitions:', error);
        }
      );
  }

  onJudgesCompetitionChange(value: judgesCompetition) {
    this.judgesCompetition.emit(value);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  setHeaderItems(isLoggedIn?) {
    let navItems;
    if (!this.isMobileView) {
      if (isLoggedIn && !this.isJudgesPortal) {
        navItems = [
          {
            label: this.userData?.firstName
              ? `${this.userData?.firstName} ${this.userData?.lastName}`
              : this.userData?.email,
            id: 'studentProfile',
            styleClass: 'right-submenu',
            visible: this.userData ? true : false,
            command: () => (this.notificationFlag = false),
            items: [
              {
                label: 'Personal Info',
                routerLink: '/student/personal-information',
                RouterLinkActive,
              },
              {
                label: 'Student Profile',
                id: 'studentProfileSubMenu',
                styleClass: 'stopHover',
                routerLink: '/student/student-profile/personality-test',
              },
              {
                label: 'Change Password',
                routerLink: '/student/change-password',
                RouterLinkActive,
              },
              {
                label: 'Logout',
                icon: 'pi pi-fw pi-sign-out',
                command: (event) => {
                  this.commonService.logout();
                  this.router.navigate(['/login']);
                },
              },
            ],
          },
        ];
      }
    } else {
      navItems = [];
    }
    if (this.isAdmin) {
      let indexOfStudentProfile = navItems.findIndex(
        (x) => x.id == 'studentProfile'
      );
      if (indexOfStudentProfile != -1) {
        navItems[0].items = [];
      }
    }
    this.items = navItems;
  }
  closeDocReview(event) {
    if (event.close) {
      this.docReviewDialog = false;
    }
  }

  showNotifications($event, action) {
    if (action == 'open') {
      this.notificationFlag = true;
    } else {
      this.notificationFlag = false;
    }
  }

  handleLogoNav() {
    if (this.userData) {
      if (this.router.url.includes('judges')) {
        this.router.navigate(['/judges/submissions']);
      } else {
        this.router.navigate(['/student/admission/US/dashboard']);
      }
    } else {
      window.location.href = 'https://algoed.co/';
    }
  }
}
