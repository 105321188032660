<ng-container *ngIf="!isTimerSimpleView">
  <div
    class="timer-section d-flex flex-grow-1 align-items-start gap-2 px-2 ps-0"
  >
    <div>
      <div
        class="text-time"
        [ngClass]="{ 'text-center': isUpcomingCompetition }"
      >
        {{ isUpcomingCompetition ? "-" : timeLeft.days }}
        <ng-container *ngIf="isLiveCompetition"> d </ng-container>
      </div>
      <div class="text-endsin" *ngIf="!isLiveCompetition">Days</div>
    </div>
    <span class="text-colon">:</span>
    <div>
      <div
        class="text-time"
        [ngClass]="{ 'text-center': isUpcomingCompetition }"
      >
        {{ isUpcomingCompetition ? "-" : timeLeft.hours }}
        <ng-container *ngIf="isLiveCompetition"> h </ng-container>
      </div>
      <div class="text-endsin" *ngIf="!isLiveCompetition">Hours</div>
    </div>
    <span class="text-colon">:</span>
    <div>
      <div
        class="text-time"
        [ngClass]="{ 'text-center': isUpcomingCompetition }"
      >
        {{ isUpcomingCompetition ? "-" : timeLeft.minutes }}
        <ng-container *ngIf="isLiveCompetition"> m </ng-container>
      </div>
      <div class="text-endsin" *ngIf="!isLiveCompetition">Minutes</div>
    </div>
  </div>
</ng-container>

<!-- For explore pages timer -->
<ng-container *ngIf="isTimerSimpleView">
  <div
    *ngIf="!isUpcomingCompetition"
    class="d-flex flex-grow-1 align-items-center fw-bold gap-1 px-2 ps-0"
  >
    <div>
      <div>{{ timeLeft.days }} d</div>
    </div>
    <span>:</span>
    <div>
      <div>{{ timeLeft.hours }} h</div>
    </div>
    <span>:</span>
    <div>
      <div>{{ timeLeft.minutes }} m</div>
    </div>
  </div>
  <!-- <ng-template #timerSection> Coming Soon... </ng-template> -->
</ng-container>
