import { Component, HostBinding } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Competition } from 'src/app/certificate-award/models/competition.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pending-multiple-payment-modal',
  templateUrl: './pending-multiple-payment-modal.component.html',
  styleUrls: ['./pending-multiple-payment-modal.component.scss'],
})
export class PendingMultiplePaymentModalComponent {
  @HostBinding('class') classes = 'd-flex flex-column h-100 overflow-hidden';
  imageBaseURL: string = environment.apiUrl;
  competitionWithPendingPayments: Competition[] = [];

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.competitionWithPendingPayments =
      this.config.data?.competitionsWithPendingPayments;
    console.log(this.competitionWithPendingPayments);
  }

  payNow(comeptitionObj: any) {
    console.log(comeptitionObj);
    const obj = {
      team_id: comeptitionObj.team_id,
      competitionID: comeptitionObj.id,
      name: comeptitionObj.name,
      registration_stage: comeptitionObj.registration_stage,
    };
    this.ref.close(obj);
  }
}
