<form class="form-group row mt-2" [formGroup]="tagForm" (ngSubmit)="saveTag()" >
  <label for="name" class="col-sm-2 col-md-4 col-form-label">Tag name</label>
  <div class="col-sm-12 col-md-8">
    <input
      type="text"
      pInputText
      formControlName="tag_name"
      placeholder="Tag name"
    />
  </div>

  <div class="mt-3 d-flex justify-content-end">
    <p-button label="Save" type="submit" icon="pi pi-save"></p-button>
  </div>
</form>
