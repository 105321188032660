import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'createArrayusingTotals',
  standalone: true,
})
export class CreateArrayusingTotalsPipe implements PipeTransform {
  transform(value: number): number[] {
    return value
      ? Array(value)
          .fill(0)
          .map((_, index) => index)
      : [];
  }
}
