import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listColor',
})
export class ListColorPipe implements PipeTransform {
  transform(value: any, arr: any): any {
    console.log(value,arr);
    arr.forEach((x) => {
     
    })
    return ''
  }
}
