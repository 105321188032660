<div class="not-found-container">
  <div class="oops-message">Oops!</div>
  <div class="status-code">404 - PAGE NOT FOUND</div>
  <div class="message">
    The page you are looking for might have been removed, had its name changed
    or is temporarily unavailable.
  </div>
  <button
    pButton
    type="button"
    (click)="navigate()"
    [label]="isLoggedIN ? 'GO TO Dashboard' : 'Login'"
    class="ui-button-primary"
  ></button>
</div>
