import { Component } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonServiceService } from '../../common-service.service';
import { Tags } from '../../models/shared.interface';
import { SharedApiService } from '../../services/shared-api.service';
import { TagFormComponent } from '../tag-form/tag-form.component';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
})
export class TagsListComponent {
  tagList: Tags[];
  ref: DynamicDialogRef;
  superAdminUSUK: string = 'US';

  constructor(
    private sharedApiService: SharedApiService,
    private commonService: CommonServiceService,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.getTagsList();
  }

  getTagsList() {
    this.sharedApiService
      .getListInForum(`questions/tags?countryShortName=${this.superAdminUSUK}`)
      .subscribe(
        (res) => {
          this.tagList = res.data.tags;
        },
        (error) => {
          this.commonService.showToast(error.error.error, 'error', 3000);
        }
      );
  }

  openTagsForm(isEdit: boolean, data?: Tags) {
    this.ref = this.dialogService.open(TagFormComponent, {
      header: isEdit ? 'Edit Tag' : 'Add Tag',
      width: '50%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      data: {
        tag: data,
        countryShortCode: this.superAdminUSUK,
      },
    });
    this.ref.onClose.subscribe((product: any) => {
      this.getTagsList();
    });
  }

  deleteTag(tag: any) {
    this.sharedApiService.deleteRecord(`questions/tags/${tag.id}`).subscribe(
      (res) => {
        this.commonService.showToast('Tag deleted successfully', 'warn', 1000);
        this.getTagsList();
      },
      (error) => {
        this.commonService.showToast(
          error.error.error ?? 'Error Deleting the tag',
          'error',
          3000
        );
      }
    );
  }
}
