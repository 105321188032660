import { Component, Input } from '@angular/core';
import { Competitions } from 'src/app/student-competitions/models/competions.models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-competition-detail-header',
  templateUrl: './competition-detail-header.component.html',
  styleUrls: ['./competition-detail-header.component.scss'],
})
export class CompetitionDetailHeaderComponent {
  @Input() competitionDetails: Competitions;

  imageBaseURL: string = environment.apiUrl;
}
