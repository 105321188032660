import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Competitions } from '../../models/competions.models';

@Component({
  selector: 'app-registration-stages',
  templateUrl: './registration-stages.component.html',
  styleUrls: ['./registration-stages.component.scss'],
})
export class RegistrationStagesComponent {
  @Input() competitionItem: Competitions;
  @Input() isCollapsible: boolean = false;
  @Output() competition = new EventEmitter<Competitions>();
  @Output() paymentModeDetails = new EventEmitter<any>();

  isExpanded: boolean = true;

  joinCompetition(item: any) {
    this.competition.emit(item);
  }

  openPaymentTerms(paymentMethod: number, item: any) {
    this.paymentModeDetails.emit({ paymentMethod, item });
  }

  isPendingCountZero(count: number): boolean {
    return count === 0;
  }
}
