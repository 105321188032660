import { Component, OnInit, OnDestroy, HostListener, HostBinding } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UsersService } from 'src/app/shared/users.service';

@Component({
  selector: 'app-email-verified',
  templateUrl: './email-verified.component.html',
  styleUrls: ['./email-verified.component.scss'],
})
export class EmailVerifiedComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMobile = window.innerWidth < 768 ? true : false;
  }

  @HostBinding('class') class = 'email-verified';

  otpCode;
  isMobile: any;
  submitted1: boolean = false;
  submitted2: boolean = false;
  isResend: boolean = false;
  studentData;
  sub: Subscription;
  otpTimer: string = `Resend OTP in 00:60`;
  otpTimerNum = 60;
  otpTimerInterval;
  isRequest: boolean = false;
  requestEmail;
  requestEmailType;
  window = window;

  constructor(
    private commonService: CommonServiceService,
    private router: Router,
    private usersService: UsersService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.requestEmail = this.config?.data?.requestEmail;
    this.requestEmailType = this.config?.data?.requestEmailType;

    this.sub = this.commonService.$curUser.subscribe((item) => {
      if (item) {
        this.studentData = item;
      } else {
        this.router.navigate(['/login']);
      }
    });

    // Automatically request OTP when the component is initialized
    this.requestOtp();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    clearInterval(this.otpTimerInterval);
  }

  startTimer() {
    if (this.otpTimerInterval) {
      clearInterval(this.otpTimerInterval);
    }

    this.otpTimerInterval = setInterval(() => {
      if (this.otpTimerNum > 0) {
        this.otpTimerNum--;
        this.otpTimer = `Resend OTP in 00:${this.otpTimerNum.toLocaleString(
          undefined,
          {
            minimumIntegerDigits: 2,
          }
        )}`;
      }
      if (this.otpTimerNum == 0) {
        this.otpTimerNum = 60;
        this.isResend = true;
        clearInterval(this.otpTimerInterval);
      }
    }, 1000);
  }

  requestOtp() {
    if (!this.requestEmail || !this.requestEmailType) {
      return;
    }
    this.startTimer();

    let obj = {
      email: this.requestEmail,
      emailType: this.requestEmailType,
      userId: this.studentData?.id ?? this.studentData?.userId,
    };

    this.usersService.sendVerificationCode({ data: obj }).subscribe(
      (item) => {
        this.commonService.showToast(item.message, 'success', 2000, 'Info');
        this.isRequest = true;
      },
      (err) => {
        this.commonService.showToast(err.error.message, 'warn', 3000, 'Info');
      }
    );
  }

  verifyOtp() {
    this.submitted2 = true;
    let obj = {
      email: this.requestEmail,
      userId: this.studentData.id,
      otpCode: this.otpCode,
    };

    this.usersService.checkVerificationCode({ data: obj }).subscribe(
      (item) => {
        this.commonService.showToast(item.message, 'success', 2000, 'Info');
        this.ref.close({ isVerified: item.success ? true : false });
      },
      (err) => {
        this.submitted2 = false;
        this.commonService.showToast(err.error.error, 'warn', 3000, 'Info');
      }
    );
  }

  resendOtp() {
    this.isResend = false;
    this.requestOtp();
  }
}
