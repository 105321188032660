<!-- Registration Details Card -->
<div
  class="details-step-card card highlighted-step-card"
  [ngClass]="{
    incomplete: !competitionItem.isPendingActionsCompleted,
    complete: competitionItem.isPendingActionsCompleted
  }"
>
  <div class="card-body p-0">
    <!-- Pending Actions -->
    <div
      class="header d-flex align-items-center justify-content-between gap-3 p-3"
    >
      <div class="d-flex align-items-center gap-3">
        <span class="status-img d-flex align-items-center">
          <img
            [src]="
              competitionItem.isPendingActionsCompleted
                ? '../../../../assets/competitions/success-tick.png'
                : '../../../../assets/competitions/alert.png'
            "
            [alt]="
              competitionItem.isPendingActionsCompleted
                ? 'Registration Complete'
                : 'Registration Incomplete'
            "
            class="img-fluid icon-pending-actions"
          />
        </span>
        <h6 class="card-title d-flex align-items-center gap-2 m-0">
          <span class="fs-5">Action Required</span>
          <span class="badge-nummber">{{
            competitionItem | pendingCount
          }}</span>
        </h6>
      </div>
      <div
        *ngIf="isCollapsible"
        class="d-flex cursor-pointer"
        (click)="isExpanded = !isExpanded"
      >
        <img
          *ngIf="!isExpanded"
          src="../../../../assets/competitions/chevron-up.svg"
          alt="Pending actions"
          class="img-fluid icon-pending-actions"
        />
        <img
          *ngIf="isExpanded"
          src="../../../../assets/competitions/chevron-down.svg"
          alt="Pending actions"
          class="img-fluid icon-pending-actions"
        />
      </div>
    </div>
    <!-- End: Pending Actions -->
    <ng-container *ngIf="isExpanded">
      <!-- Pay Now -->
      <div
        *ngIf="competitionItem?.payment_status !== 'Paid'"
        class="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between gap-3 flex-wrap p-3"
        [ngClass]="{ 'border-bottom': +competitionItem.individual !== 1 }"
      >
        <div class="d-flex align-items-center gap-3 flex-grow-1">
          <img
            class="status-img"
            [src]="
              competitionItem.isPendingActionsCompleted
                ? '../../../../assets/competitions/success-tick-light.png'
                : '../../../../assets/competitions/exclamatory.png'
            "
            [alt]="
              competitionItem.isPendingActionsCompleted
                ? 'Complete'
                : 'Incomplete'
            "
          />
          <!-- Payment Required -->
          <div>
            <h6 class="card-title fs-6 m-0">Payment Required</h6>
            <!-- TODO : make it  competitionItem.late_registration_close dynamic  -->
            <!-- <p class="m-0">
              Hurry up!
              {{ competitionItem?.registration_stage + " " + "Registration" }}
              will end at
              {{
                competitionItem.late_registration_close
                  | date : "d MMM 'at' h:mm a" : "UTC"
              }}
            </p> -->
          </div>
        </div>
        <div class="flex-shrink-0">
          <button
            class="btn w-100"
            [ngClass]="{
              'btn-paid': competitionItem?.payment_status == 'Paid',
              'btn-primary': competitionItem?.payment_status != 'Paid'
            }"
            [disabled]="
              competitionItem?.payment_status == 'Paid' ||
              competitionItem?.payment_status == 'Waived'
            "
            (click)="openPaymentTerms(1, competitionItem)"
          >
            {{
              competitionItem?.payment_status == "Paid" ||
              competitionItem?.payment_status == "Waived"
                ? competitionItem?.payment_status == "Waived"
                  ? "Waived"
                  : "Paid"
                : "Pay Now"
            }}
          </button>
        </div>
      </div>
      <!-- End: Pay Now -->
      <!-- Invite Now -->
      <div
        *ngIf="
          competitionItem.individual !== 1 &&
          !(
            competitionItem.required_team_members -
              competitionItem.member_count <=
            0
          )
        "
        class="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between gap-3 flex-wrap p-3"
        [ngClass]="{ 'border-bottom': +competitionItem.individual !== 1 }"
      >
        <div class="d-flex align-items-center gap-3 flex-grow-1">
          <img
            *ngIf="
              competitionItem.member_count <
              competitionItem.required_team_members
            "
            class="status-img"
            src="../../../../assets/competitions/exclamatory.png
            "
            alt="Incomplete"
          />
          <img
            *ngIf="
              competitionItem.required_team_members -
                competitionItem.member_count <=
              0
            "
            class="status-img"
            src="../../../../assets/competitions/success-tick-light.png
            "
            alt="Complete"
          />

          <div>
            <h6 class="card-title fs-6 m-0">
              <span>Invite additional teammates</span>
              <span class="mx-2">
                <img
                  src="../../../../assets/competitions/user-group.svg"
                  alt="user-group"
                  class="img-fluid"
                />
              </span>
              <span style="color: #797979"
                >{{ competitionItem.member_count }}/{{
                  competitionItem.max_team_members
                }}</span
              >
            </h6>
            <p class="m-0">
              {{
                competitionItem.required_team_members -
                  competitionItem.member_count >
                0
                  ? competitionItem.required_team_members -
                    competitionItem.member_count +
                    " more teammate required"
                  : "Minimum team size achieved."
              }}
            </p>
          </div>
        </div>

        <div class="flex-shrink-0">
          <button
            class="btn btn-primary w-100"
            (click)="joinCompetition(competitionItem)"
            [disabled]="
              competitionItem.max_team_members == competitionItem.member_count
            "
          >
            Invite Now
          </button>
        </div>
      </div>
      <!-- End: Invite Now -->

      <!-- Pay for Teammates -->
      <div
        class="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between gap-3 flex-wrap p-3"
        *ngIf="
          competitionItem.individual !== 1 &&
          competitionItem.member_paid_count !== competitionItem.member_count
        "
      >
        <div class="d-flex align-items-center gap-3 flex-grow-1">
          <img
            *ngIf="
              competitionItem.member_paid_count !== competitionItem.member_count
            "
            class="status-img"
            src="../../../../assets/competitions/exclamatory.png
            "
            alt="Incomplete"
          />
          <img
            *ngIf="
              competitionItem.member_paid_count == competitionItem.member_count
            "
            class="status-img"
            src="../../../../assets/competitions/success-tick-light.png
            "
            alt="Complete"
          />
          <!-- Payment Required from Teammates -->
          <div>
            <h6 class="card-title fs-6 m-0">
              <span>Payment Required from Teammates</span>
              <span class="mx-2">
                <img
                  src="../../../../assets/competitions/user-group.svg"
                  alt="user-group"
                  class="img-fluid"
                />
              </span>
              <span style="color: #797979"
                >{{ competitionItem.member_paid_count }}/{{
                  competitionItem.member_count
                }}
                paid</span
              >
            </h6>
            <p class="m-0">Remind your teammates to pay before its too late!</p>
          </div>
        </div>

        <div class="flex-shrink-0">
          <button
            class="btn w-100"
            [ngClass]="{
              'btn-paid':
                competitionItem.member_paid_count ==
                competitionItem.member_count,
              'btn-primary':
                competitionItem?.payment_status != 'Paid' ||
                competitionItem.member_paid_count !=
                  competitionItem.member_count
            }"
            (click)="openPaymentTerms(2, competitionItem)"
            [disabled]="
              competitionItem.member_paid_count == competitionItem.member_count
            "
          >
            {{
              competitionItem.member_paid_count == competitionItem.member_count
                ? "Paid"
                : "Pay for Teammates"
            }}
          </button>
        </div>
      </div>
      <!-- End: Pay for Teammates -->

      <!-- Actions Required -->
      <div
        class="d-flex align-items-center gap-3 p-3"
        *ngIf="isPendingCountZero(competitionItem | pendingCount)"
      >
        <img
          src="../../../../assets/competitions/success-tick-light.png"
          alt="Registration Complete"
          class="img-fluid"
          style="width: 24px; height: 24px"
        />
        <span>Registration Complete</span>
      </div>
      <!-- End: Actions Required -->
    </ng-container>
  </div>
</div>
<!-- End: Registration Details Card -->
