<div class="d-flex flex-column h-100">
  <!-- Content -->
  <div class="submission-dialog-content flex-grow-1 overflow-auto">
    <figure class="m-0 mb-4 text-center">
      <img
        class="delete-icon"
        src="../../../../assets/svgs/question-mark.svg"
        alt="Delete Icon"
      />
    </figure>
    <p class="fs-5">
      You are about to submit this entry <b *ngIf="isIndividual">on behalf of your team</b>. Please
      confirm this is your <b>final version</b> before submitting
    </p>
    <p class="mb-0">
      <span class="text-danger">Important:</span> Once you confirm, no further
      changes can be <br> made to your submission.
    </p>
  </div>
  <!-- End: Content -->
  <!-- Footer Content -->
  <footer
    class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-center p-4 flex-shrink-0 gap-3"
  >
    <button class="btn btn-outline-cancel w-100 mx-0" (click)="cancel()">
      Cancel
    </button>
    <button class="btn btn-primary w-100 mx-0" (click)="confirmAndSubmit()">
      Confirm & Submit
    </button>
  </footer>
  <!-- End: Footer Content -->
</div>
