import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDynamicStyle]',
})
export class DynamicStyleDirective {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    // console.log('konstruktorissa');
  }

  ngAfterViewInit() {
    const imgElements =
      this.elementRef.nativeElement.getElementsByTagName('img');
    for (let i = 0; i < imgElements.length; i++) {
      const img = imgElements[i];
      this.renderer.setStyle(img, 'width', '250px');
      this.renderer.setStyle(img, 'height', '60px');
    }
  }
}
