<div class="card-container">
  <div class="auth-content card-padding">
    <!-- signup_level == 0 && !studentData -->
    <div class="step_one" *ngIf="signup_level == 0 && !studentData">
      <div class="text-lg-start text-center algoed_logo">
        <img src="../../assets/icons/logo-2.svg" alt="algoedlogo" srcset="" />
      </div>
      <h3 class="text-lg-start text-center">Create an account</h3>
      <form
        [formGroup]="signupStep1Form"
        (ngSubmit)="newSignup()"
        novalidate
        class="card-container"
      >
        <div class="input-group">
          <div class="mb-2">
            <label for="Name" class="block mb-0">Name</label>
          </div>
          <div class="p-input-icon-left w-100">
            <i class="pi pi-user"></i>
            <input
              pInputText
              formControlName="fullName"
              class="p-inputtext-lg"
              placeholder="Full Name"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
        </div>
        <div class="input-group">
          <div class="mb-2">
            <label for="Email" class="block mb-0">Email</label>
          </div>
          <div class="p-input-icon-left w-100">
            <i class="pi pi-inbox"></i>
            <input
              pInputText
              class="p-inputtext-lg"
              placeholder="Email ID"
              formControlName="email"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="disableEmail"
            />
          </div>
          <div
            *ngIf="
              f1['email'].invalid && (f1['email'].dirty || f1['email'].touched)
            "
            class="invalid-feedback"
          >
            <div *ngIf="f1['email'].errors?.['required']">
              Email is required
            </div>
            <div *ngIf="f1['email'].errors?.['email']">Email is invalid</div>
          </div>
        </div>

        <div class="input-group">
          <div class="mb-2">
            <label for="Password" class="block mb-0">Password</label>
          </div>
          <span class="p-input-icon-left p-input-icon-right">
            <i class="pi pi-lock"></i>
            <input
              id="password"
              [type]="showPass ? 'text' : 'password'"
              formControlName="password"
              pInputText
              placeholder="Password"
              class="p-inputtext-lg"
              minlength="8"
              maxlength="12"
            />
            <i
              style="cursor: pointer"
              class="pi"
              [ngClass]="{ 'pi-eye': !showPass, 'pi-eye-slash': showPass }"
              (click)="showPassFn()"
            ></i>
          </span>
          <div
            *ngIf="
              f1['password'].invalid &&
              (f1['password'].dirty || f1['password'].touched)
            "
            class="invalid-feedback"
          >
            <div *ngIf="f1['password'].errors?.['required']">
              Password is required
            </div>
            <div *ngIf="f1['password'].errors?.['minlength']">
              Password must be at least 8 characters
            </div>
            <div *ngIf="f1['password'].errors?.['maxlength']">
              Password must not exceed 12 characters
            </div>
          </div>
        </div>

        <ul>
          <li>
            <p-checkbox
              class="primary-blue"
              name="groupname"
              formControlName="acceptTerms"
              [ngClass]="{
                'is-invalid': form1_submitted && f1['acceptTerms'].errors
              }"
              [binary]="true"
            ></p-checkbox
            ><span class="checkbox-text">
              I have read, understood and agree to be bound by the
              <a [href]="redirectLinks.termsAndConditions" target="_blank"
                >Terms & Conditions</a
              >
              and consent to the
              <a [href]="redirectLinks.privacyPolicy" target="_blank"
                >Privacy Policy</a
              >.</span
            >
          </li>
          <li>
            <p-checkbox
              class="primary-blue"
              name="isEighteenPlus"
              [(ngModel)]="isEighteenPlus"
              [ngModelOptions]="{ standalone: true }"
              [value]="false"
              [binary]="true"
            ></p-checkbox
            ><span class="checkbox-text" for="isEighteenPlus">
              I hereby declare that I am at least 18 years of age or I have
              parental/ guardian consent to be bound by the Terms & Conditions
              and to give consent to the Privacy Policy.</span
            >
          </li>
        </ul>
        <!-- [disabled]="form1_submitted || signupStep1Form.invalid" -->
        <button
          pButton
          pRipple
          class="p-button p-primary w-100"
          label="Continue"
        ></button>
      </form>

      <div class="alert alert-danger" *ngIf="showNavLogin">
        This email is already associated with an account. Do you want to
        <a routerLink="/login">login</a> instead?
      </div>

      <div class="mt-4">
        <span class="sign-up my-0" (click)="navigateToLogin()"
          >Already have an account? <a style="cursor: pointer">Login</a></span
        >
      </div>
    </div>

    <div class="step_two" *ngIf="signup_level == 1 && studentData">
      <h3 class="text-lg-start text-center">Create Free Account</h3>
    </div>
    <div class="step_two" *ngIf="signup_level == 2 && studentData">
      <h3 class="text-lg-start text-center">Account Setup</h3>

      <form
        [formGroup]="signupStep3Form"
        (ngSubmit)="updateAccount(signupStep3Form.getRawValue())"
        novalidate
        class="card-container"
      >
        <div class="input_row align-center flex-wrap gap-2">
          <div class="flex-70">
            <label>Your email</label>
            <h4>{{ studentData?.email }}</h4>
          </div>

          <div>
            <button
              pButton
              pRipple
              type="button"
              class="p-primary mt-0"
              label="Verify Email"
              (click)="verifyEmail('student')"
              *ngIf="!isVerifiedEmail"
            ></button>
            <button
              pButton
              pRipple
              class="verified_red mt-0"
              label="Verified"
              [disabled]="true"
              *ngIf="isVerifiedEmail"
            ></button>
          </div>
        </div>

        <!-- <div class="input_row align-center" *ngIf="accountSetup__isStudent"> -->
        <!-- Code for parent email -->
        <!-- </div> -->

        <div class="input_row">
          <div class="input-group">
            <label for="Country" class="block">Country</label>
            <p-dropdown
              [options]="countries"
              [filter]="true"
              formControlName="country"
              placeholder="Select country"
              [virtualScrollItemSize]="25"
              optionLabel="name"
              optionValue="name"
            >
              <ng-template pTemplate="selectedItem">
                <div>
                  <div>{{ selectedCountry }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <div
              *ngIf="
                f3['country'].invalid &&
                (f3['country'].dirty || f3['country'].touched)
              "
              class="invalid-feedback"
            >
              <div *ngIf="f3['country'].errors?.['required']">
                Country is required
              </div>
            </div>
          </div>
        </div>
        <div class="input_row">
          <div class="input-group">
            <label for="State" class="block"
              >What is your highschool/ secondary school year?</label
            >
            <span
              class="show-conversion-font text-primary text-decoration-underline"
              (click)="showConversion()"
              >{{ isConversionVisible ? "Hide Conversion" : "Show Conversion" }}
            </span>
            <div class="w-100 mt-2" *ngIf="isConversionVisible">
              <img
                src="../../../assets/grad-year-conversion.jpeg"
                class="w-100"
                srcset=""
              />
            </div>
            <p-dropdown
              class="w-100 full-width"
              formControlName="gradYear"
              placeholder="Select a graduation year"
              [filter]="true"
              [virtualScrollItemSize]="25"
              [options]="nextSixYears"
              [appendTo]="'body'"
            >
            </p-dropdown>
          </div>
        </div>
        <div class="input_row gap-2">
          <p-checkbox
            class="primary-blue"
            name="groupname"
            formControlName="sendMarketingEmails"
            [binary]="true"
          ></p-checkbox
          ><span class="checkbox-text">
            I would like to opt out from receiving special offers and latest
            news relating to AlgoEd's products. I do not consent to the use of
            my personal data for direct marketing purposes.</span
          >
        </div>
        <button
          pButton
          pRipple
          class="p-primary"
          label="Finish"
          [disabled]="!signupStep3Form.valid"
        ></button>
      </form>
    </div>
  </div>
</div>
