import { HeadersAndKeyNames } from 'src/app/admin/admin.interface';
import {
  GenericFormControls,
  PersonalityTestTypes,
  UserRole,
} from '../models/shared.interface';

export class MainConstants {
  public YEARS: Array<any> | undefined;
  public QUESTIONS: Array<any> | undefined = [];
  activityTypeArrNew;

  constructor() {}

  labels = {
    marketingLabel:
      'Don’t email me about product updates. If this box is left unchecked, AlgoEd will occasionally send helpful and relevant emails. You can unsubscribe at any time.',
  };

  timeZone: { timezone: string; offset: string }[] = [
    { timezone: '(GMT -12:00) Eniwetok, Kwajalein', offset: '-12' },
    { timezone: '(GMT -11:00) Midway Island, Samoa', offset: '-11' },
    { timezone: '(GMT -10:00) Hawaii', offset: '-10' },
    { timezone: '(GMT -9:30) Taiohae', offset: '-09:30' },
    { timezone: '(GMT -9:00) Alaska', offset: '-09' },
    { timezone: '(GMT -8:00) Pacific Time (US & Canada)', offset: '-08' },
    { timezone: '(GMT -7:00) Mountain Time (US & Canada)', offset: '-07' },
    {
      timezone: '(GMT -6:00) Central Time (US & Canada), Mexico City',
      offset: '-06',
    },
    {
      timezone: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
      offset: '-05',
    },
    { timezone: '(GMT -4:30) Caracas', offset: '-04:30' },
    {
      timezone: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
      offset: '-04',
    },
    { timezone: '(GMT -3:30) Newfoundland', offset: '-03:30' },
    { timezone: '(GMT -3:00) Brazil, Buenos Aires, Georgetown', offset: '-03' },
    { timezone: '(GMT -2:00) Mid-Atlantic', offset: '-02' },
    { timezone: '(GMT -1:00) Azores, Cape Verde Islands', offset: '-01' },
    {
      timezone: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
      offset: '+00',
    },
    {
      timezone: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
      offset: '+01',
    },
    { timezone: '(GMT +2:00) Kaliningrad, South Africa', offset: '+02' },
    {
      timezone: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
      offset: '+03',
    },
    { timezone: '(GMT +3:30) Tehran', offset: '+03:30' },
    { timezone: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi', offset: '+04' },
    { timezone: '(GMT +4:30) Kabul', offset: '+04:30' },
    {
      timezone: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
      offset: '+05',
    },
    {
      timezone: '(GMT +5:30) Mumbai, Kolkata, Chennai, New Delhi',
      offset: '+05:30',
    },
    { timezone: '(GMT +5:45) Kathmandu, Pokhara', offset: '+05:45' },
    { timezone: '(GMT +6:00) Almaty, Dhaka, Colombo', offset: '+06' },
    { timezone: '(GMT +6:30) Yangon, Mandalay', offset: '+06:30' },
    { timezone: '(GMT +7:00) Bangkok, Hanoi, Jakarta', offset: '+07' },
    {
      timezone: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
      offset: '+08',
    },
    { timezone: '(GMT +8:45) Eucla', offset: '+08:45' },
    {
      timezone: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
      offset: '+09',
    },
    { timezone: '(GMT +9:30) Adelaide, Darwin', offset: '+09:30' },
    {
      timezone: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
      offset: '+10',
    },
    { timezone: '(GMT +10:30) Lord Howe Island', offset: '+10:30' },
    {
      timezone: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
      offset: '+11',
    },
    { timezone: '(GMT +11:30) Norfolk Island', offset: '+11:30' },
    {
      timezone: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
      offset: '+12',
    },
    { timezone: '(GMT +12:45) Chatham Islands', offset: '+12:45' },
    { timezone: '(GMT +13:00) Apia, Nukualofa', offset: '+13' },
    { timezone: '(GMT +14:00) Line Islands, Tokelau', offset: '+14' },
  ];

  // emailRegex =
  //   /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|((([a-zA-Z0-9]+-)*[a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;

  linkRegex =
    /^(https?:\/\/)?([\w.-]+)+(:\d+)?(\/[\w-._~:/?#[\]@!$&'()*+,;=%]*)?$/i;

  withoutDotComRegex =
    /\b(?:https?|ftp):\/\/[a-z0-9-._~%!$&'()*+,;=:@]+(?:[a-z0-9-._~%!$&'()*+,;=:@\/]*)?/gi;

  youtubeRegex =
    /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|shorts\/|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  redirectLinks = {
    termsAndConditions: 'https://www.algoed.co/legal/terms-conditions',
    privacyPolicy: 'https://www.algoed.co/legal/privacy-policy',
    competitionRegistrationAndPaymentSystem:
      'https://www.algoed.co/legal-docs/competition-registration-and-payment-system-terms-of-use',
  };

  unitedStatesStates: any[] = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ];

  nextSixYears = [
    { gradeByYear: 'Grade 6 / Year 7', grade: 6, year: 7 },
    { gradeByYear: 'Grade 7 / Year 8', grade: 7, year: 8 },
    { gradeByYear: 'Grade 8 / Year 9', grade: 8, year: 9 },
    { gradeByYear: 'Grade 9 / Year 10', grade: 9, year: 10 },
    { gradeByYear: 'Grade 10 / Year 11', grade: 10, year: 11 },
    { gradeByYear: 'Grade 11 / Year 12', grade: 11, year: 12 },
    { gradeByYear: 'Grade 12 / Year 13', grade: 12, year: 13 },
  ];

  public actions = [
    {
      label: 'Welcome',
      value: 'welcome',
    },
    {
      label: 'Submission Confirmation',
      value: 'submission_confirmation',
    },
  ];

  public rollingTypeList = [
    {
      label: 'Rolling',
      value: 1,
    },
    {
      label: 'Non-Rolling',
      value: 0,
    },
  ];

  judgesList: HeadersAndKeyNames[] = [
    {
      header: 'Name',
      key: 'name',
    },
    {
      header: 'Username',
      key: 'username',
    },
    {
      header: 'Group Name',
      key: 'groupsname',
    },
    {
      header: 'Password',
      key: 'password',
    },
    {
      header: 'Action',
      key: 'action',
    },
  ];

  groupsList: HeadersAndKeyNames[] = [
    {
      header: 'Group Name',
      key: 'name',
    },
    {
      header: 'Display Panel Grades Allowed to',
      key: 'judgesName',
    },
    {
      header: 'Total Judges',
      key: 'total_judges',
    },
    {
      header: 'Assigned Competition',
      key: 'competition_name',
    },
    {
      header: 'Action',
      key: 'action',
    },
  ];

  sharableButtons: any[] = [
    {
      id: 1,
      name: 'Copy Link',
      icon: '../../../../assets/share-icons/copy-link-icon.svg',
    },
    {
      id: 2,
      name: 'Whatsapp',
      icon: '../../../../assets/share-icons/whatsapp.png',
    },
    {
      id: 3,
      name: 'Facebook',
      icon: '../../../../assets/share-icons/fb.png',
    },
    {
      id: 4,
      name: 'Twitter',
      icon: '../../../../assets/share-icons/twitter.png',
    },
    {
      id: 5,
      name: 'Email',
      icon: '../../../../assets/share-icons/email.svg',
    },
    {
      id: 6,
      name: 'linkedin',
      icon: '../../../../assets/share-icons/linkedin.png',
    },
  ];

  roleID: UserRole[] = [
    {
      id: 11,
      role: 'Admin',
    },
    {
      id: 12,
      role: 'Editor',
    },
    {
      id: 15,
      role: 'Viewer',
    },
    {
      id: 10,
      role: 'Owner',
    },
  ];

  personalityTestTypes: PersonalityTestTypes[] = [
    {
      id: 1,
      testTitle: 'Analysts',
      color: 'analysts',
      subTests: [
        {
          id: 1,
          subTestTitle: 'Architect',
          subTestCode: 'INTJ',
          imagePath:
            '../../../../assets/images/student-personality/analysts-icon.svg',
        },
        {
          id: 2,
          subTestTitle: 'Logician',
          subTestCode: 'INTP',
          imagePath:
            '../../../../assets/images/student-personality/logician.svg',
        },
        {
          id: 3,
          subTestTitle: 'Commander',
          subTestCode: 'ENTJ',
          imagePath:
            '../../../../assets/images/student-personality/commander.svg',
        },
        {
          id: 4,
          subTestTitle: 'Debater',
          subTestCode: 'ENTP',
          imagePath:
            '../../../../assets/images/student-personality/debater.svg',
        },
      ],
    },
    {
      id: 2,
      testTitle: 'Diplomats',
      color: 'diplomats',
      subTests: [
        {
          id: 5,
          subTestTitle: 'Advocate',
          subTestCode: 'INFJ',
          imagePath:
            '../../../../assets/images/student-personality/advocate.svg',
        },
        {
          id: 6,
          subTestTitle: 'Mediator',
          subTestCode: 'INFP',
          imagePath:
            '../../../../assets/images/student-personality/mediator.svg',
        },
        {
          id: 7,
          subTestTitle: 'Protagonist',
          subTestCode: 'ENFJ',
          imagePath:
            '../../../../assets/images/student-personality/protagonist.svg',
        },
        {
          id: 8,
          subTestTitle: 'Campaigner',
          subTestCode: 'ENFP',
          imagePath:
            '../../../../assets/images/student-personality/campaigner.svg',
        },
      ],
    },
    {
      id: 3,
      testTitle: 'Sentinels',
      color: 'sentinals',
      subTests: [
        {
          id: 9,
          subTestTitle: 'Logistician',
          subTestCode: 'ISTJ',
          imagePath:
            '../../../../assets/images/student-personality/logistician.svg',
        },
        {
          id: 10,
          subTestTitle: 'Defender',
          subTestCode: 'ISFJ',
          imagePath:
            '../../../../assets/images/student-personality/defender.svg',
        },
        {
          id: 11,
          subTestTitle: 'Executive',
          subTestCode: 'ESTJ',
          imagePath:
            '../../../../assets/images/student-personality/executive.svg',
        },
        {
          id: 12,
          subTestTitle: 'Consul',
          subTestCode: 'ESFJ',
          imagePath: '../../../../assets/images/student-personality/consul.svg',
        },
      ],
    },
    {
      id: 4,
      testTitle: 'Explorers',
      color: 'explorers',
      subTests: [
        {
          id: 13,
          subTestTitle: 'Virtuoso',
          subTestCode: 'ISTP',
          imagePath:
            '../../../../assets/images/student-personality/virtuoso.svg',
        },
        {
          id: 14,
          subTestTitle: 'Adventurer',
          subTestCode: 'ISFP',
          imagePath:
            '../../../../assets/images/student-personality/adventurer.svg',
        },
        {
          id: 15,
          subTestTitle: 'Entrepreneur',
          subTestCode: 'ESTP',
          imagePath:
            '../../../../assets/images/student-personality/entreprenuer.svg',
        },
        {
          id: 16,
          subTestTitle: 'Entertainer',
          subTestCode: 'ESFP',
          imagePath:
            '../../../../assets/images/student-personality/entertainer.svg',
        },
      ],
    },
  ];

  studentProfileTabs: any[] = [
    {
      id: 1,
      title: 'Personality Test',
      path: './personality-test',
      nextRouter: '/academic-record',
      isActive: false,
    },
    {
      id: 2,
      title: 'Academic Record',
      path: './academic-record',
      nextRouter: '/standardized-test/sat-scores',
      backRouter: '/personality-test',
      isActive: false,
    },
    {
      id: 3,
      title: 'Standardized Test',
      path: './standardized-test',
      isExpanded: false,
      isActive: false,
      isChildren: [
        {
          id: 1,
          title: 'SAT Scores (for US)',
          path: 'standardized-test/sat-scores',
          parentID: 3,
          nextRouter: '/standardized-test/act-scores',
          backRouter: '/academic-record',
          isActive: false,
        },
        {
          id: 2,
          title: 'ACT Score (for US)',
          path: 'standardized-test/act-scores',
          parentID: 3,
          nextRouter: '/standardized-test/oxbridge-scores',
          backRouter: '/standardized-test/sat-scores',
          isActive: false,
        },
        {
          id: 3,
          title: 'Oxbridge Standardized Test Scores (for UK)',
          path: 'standardized-test/oxbridge-scores',
          parentID: 3,
          nextRouter: '/standardized-test/non-oxbridgess-scores',
          backRouter: '/standardized-test/act-scores',
          isActive: false,
        },
        {
          id: 3,
          title: 'Non-Oxbridge UK Standardized Test Scores (for UK)',
          path: 'standardized-test/non-oxbridgess-scores',
          parentID: 3,
          nextRouter: '/academic-interest',
          backRouter: '/standardized-test/oxbridge-scores',
          isActive: false,
        },
      ],
    },
    {
      id: 4,
      title: 'Academic Interest',
      path: './academic-interest',
      nextRouter: '/university-plans',
      backRouter: '/standardized-test/non-oxbridgess-scores',
      isActive: false,
    },
    {
      id: 5,
      title: 'University Plans',
      path: './university-plans',
      nextRouter: '/extra-curriculum-activities',
      isActive: false,
      backRouter: '/academic-interest',
    },
    {
      id: 6,
      title: 'Extra-Curricular Activities (for US)',
      path: './extra-curriculum-activities',
      isActive: false,
      nextRouter: '/career-interest',
      backRouter: '/university-plans',
    },
    {
      id: 7,
      title: 'Career Interest (for UK)',
      isActive: false,
      path: './career-interest',
      nextRouter: '/university-subject-interest',
      backRouter: '/extra-curriculum-activities',
    },
    {
      id: 8,
      title: 'University Subject Interest (for UK)',
      isActive: false,
      path: './university-subject-interest',
      nextRouter: '/super-curricular-activities',
      backRouter: '/career-interest',
    },
    {
      id: 9,
      title: 'Supercurricular Activities (for UK)',
      path: './super-curricular-activities',
      isActive: false,
      backRouter: '/university-subject-interest',
    },
  ];

  academicRecordCirculum: any[] = [
    {
      id: 1,
      label: 'IB',
    },
    {
      id: 2,
      label: 'A-Levels',
    },
    {
      id: 3,
      label: 'AP',
    },
    {
      id: 4,
      label: 'Others',
    },
  ];
  gradesAsPerCirculum: any[] = [
    {
      parentId: 'IB',
      id: 1,
      label: 7,
    },
    {
      parentId: 'IB',
      id: 2,
      label: 6,
    },
    {
      parentId: 'IB',
      id: 3,
      label: 5,
    },
    {
      parentId: 'IB',
      id: 4,
      label: 4,
    },
    {
      parentId: 'IB',
      id: 5,
      label: 3,
    },
    {
      parentId: 'IB',
      id: 6,
      label: 2,
    },
    {
      parentId: 'IB',
      id: 7,
      label: 1,
    },
    {
      parentId: 'A-Levels',
      id: 8,
      label: 'A*',
    },
    {
      parentId: 'A-Levels',
      id: 9,
      label: 'A',
    },
    {
      parentId: 'A-Levels',
      id: 10,
      label: 'B',
    },
    {
      parentId: 'A-Levels',
      id: 11,
      label: 'C',
    },
    {
      parentId: 'A-Levels',
      id: 12,
      label: 'D',
    },
    {
      parentId: 'A-Levels',
      id: 13,
      label: 'E',
    },
    {
      parentId: 'A-Levels',
      id: 14,
      label: 'U',
    },
    {
      parentId: 'AP',
      id: 15,
      label: 5,
    },
    {
      parentId: 'AP',
      id: 16,
      label: 4,
    },
    {
      parentId: 'AP',
      id: 17,
      label: 3,
    },
    {
      parentId: 'AP',
      id: 18,
      label: 2,
    },
    {
      parentId: 'AP',
      id: 19,
      label: 1,
    },
  ];

  satScoresForm: GenericFormControls[] = [
    {
      controlName: 'id',
      labels: 'ID',
      placeHolder: 'Enter Composite Score',
      type: 'number',
      feildType: 'text',
      keyFilters: 'num',
      rank: 0,
      value: null,
      isRequired: false,
    },
    {
      controlName: 'sat_score_composite',
      labels: 'Composite Score',
      placeHolder: 'Enter Composite Score',
      type: 'number',
      feildType: 'text',
      keyFilters: 'num',
      rank: 1,
      value: null,
      isRequired: true,
    },
    {
      controlName: 'sat_score_math_score',
      labels: 'Math Score',
      feildType: 'text',
      placeHolder: 'Enter Math Score',
      rank: 2,
      keyFilters: 'num',
      type: 'number',
      value: null,
      isRequired: true,
    },
    {
      controlName: 'sat_score_reading_and_writing_score',
      labels: 'Reading and Writing Score',
      feildType: 'text',
      placeHolder: 'Enter Reading and Writing Score',
      rank: 3,
      type: 'number',
      keyFilters: 'num',
      value: null,
      isRequired: true,
    },
  ];

  actScoresForm: GenericFormControls[] = [
    {
      controlName: 'id',
      labels: 'ID',
      placeHolder: 'Enter Composite Score',
      type: 'number',
      feildType: 'text',
      rank: 0,
      keyFilters: 'num',
      value: null,
      isRequired: false,
    },
    {
      controlName: 'act_score_composite',
      labels: 'Composite Score',
      placeHolder: 'Enter Composite Score',
      type: 'number',
      feildType: 'text',
      keyFilters: 'num',
      rank: 1,
      value: null,
      isRequired: true,
    },
    {
      controlName: 'act_score_english',
      labels: 'English Score',
      placeHolder: 'Enter English Score',
      type: 'number',
      keyFilters: 'num',
      feildType: 'text',
      rank: 2,
      value: null,
      isRequired: true,
    },
    {
      controlName: 'act_score_math',
      labels: 'Math Score',
      placeHolder: 'Enter Math Score',
      type: 'number',
      feildType: 'text',
      keyFilters: 'num',
      rank: 3,
      isRequired: true,
      value: null,
    },
    {
      controlName: 'act_score_reading',
      labels: 'Reading Score',
      placeHolder: 'Enter Reading Score',
      type: 'number',
      feildType: 'text',
      rank: 5,
      isRequired: true,
      value: null,
      keyFilters: 'num',
    },
    {
      controlName: 'act_score_science',
      labels: 'Science Score',
      placeHolder: 'Enter Science Score',
      type: 'number',
      feildType: 'text',
      keyFilters: 'num',
      rank: 4,
      isRequired: true,
      value: null,
    },
    {
      controlName: 'act_score_writing',
      labels: 'Writing Score',
      placeHolder: 'Enter Writing Score',
      type: 'number',
      feildType: 'text',
      rank: 5,
      keyFilters: 'num',
      isRequired: true,
      value: null,
    },
  ];

  oxBridgeForm: GenericFormControls[] = [
    {
      controlName: 'oxbridge_standardized_test',
      labels: 'Standardized Test',
      placeHolder: 'Select a test',
      type: 'number',
      feildType: 'dropdown',
      options: [
        'CAT',
        'CLT',
        'ECAA',
        'EGAA',
        'ELAT',
        'HAT',
        'MAT',
        'MLAT',
        'NSAA',
        'OLAT',
        'PAT',
        'Philosophy',
        'STEP',
        'TMUA',
        'TSA',
      ],
      rank: 1,
      value: null,
      isRequired: true,
    },
    {
      controlName: 'oxbridge_standardized_test_scores',
      labels: 'Standardized Test score',
      placeHolder: 'Enter Standardized Test score',
      type: 'number',
      keyFilters: 'num',
      feildType: 'text',
      rank: 2,
      value: null,
      isRequired: true,
    },
  ];

  nonOxBridgeForm: GenericFormControls[] = [
    {
      controlName: 'id',
      labels: 'ID',
      placeHolder: 'Enter Composite Score',
      type: 'number',
      feildType: 'text',
      rank: 0,
      value: null,
      keyFilters: 'num',
      isRequired: false,
    },
    {
      controlName: 'non_oxbridge_standardized_test',
      labels: 'Standardized Test',
      placeHolder: 'Select a test',
      type: 'number',
      feildType: 'dropdown',
      options: ['BMAT', 'LNAT'],
      rank: 1,
      value: null,
      isRequired: true,
    },
    {
      controlName: 'non_oxbridge_standardized_test_scores',
      labels: 'Standardized Test score',
      placeHolder: 'Enter Standardized Test score',
      type: 'number',
      feildType: 'text',
      keyFilters: 'num',
      rank: 2,
      value: null,
      isRequired: true,
    },
  ];

  top3UniversityPlansForm: any[] = [
    {
      controlName: 'countryShortName',
    },
    {
      controlName: 'universityId',
    },
    {
      controlName: 'orderBy',
    },
  ];

  organisationListFiltersFormControls: GenericFormControls[] = [
    {
      controlName: 'name',
      labels: 'Name',
      placeHolder: 'Enter Organisation Name',
      type: 'text',
      feildType: 'text',
      keyFilters: 'alphanum',
      rank: 0,
      value: null,
      isRequired: false,
    },
    {
      labels: 'No of Students:',
      type: 'label',
      feildType: 'label',
      keyFilters: 'int',
      isFullWidth: true,
      rank: 2,
      value: null,
      isRequired: false,
    },
    {
      controlName: 'country',
      labels: 'Country',
      placeHolder: 'Select country',
      keyFilters: 'alphanum',
      type: 'text',
      feildType: 'dropdown',
      options: ['USA', 'UK', 'India', 'Nepal'],
      rank: 1,
      value: null,
      isRequired: false,
    },
    {
      controlName: 'studentsMin',
      labels: 'Min.',
      placeHolder: 'Enter Min. no. of student',
      keyFilters: 'int',
      type: 'text',
      feildType: 'text',
      rank: 3,
      value: null,
      isRequired: false,
    },
    {
      controlName: 'studentsMax',
      labels: 'Max.',
      keyFilters: 'int',
      placeHolder: 'Enter Max. no. of student',
      type: 'text',
      feildType: 'text',
      rank: 4,
      value: null,
      isRequired: false,
    },
    {
      labels: 'Total spend in last 30 days ($):',
      type: 'label',
      feildType: 'label',
      isFullWidth: true,
      rank: 5,
      value: null,
      isRequired: false,
    },
    {
      controlName: 'spentMinMonth',
      labels: 'Min.',
      placeHolder: 'Enter Min. time spent in last 30 days',
      keyFilters: 'int',
      type: 'text',
      feildType: 'text',
      rank: 6,
      value: null,
      isRequired: false,
    },
    {
      controlName: 'spentMaxMonth',
      labels: 'Max.',
      keyFilters: 'int',
      placeHolder: 'Enter Max. time spent in last 30 days',
      type: 'text',
      feildType: 'text',
      rank: 7,
      value: null,
      isRequired: false,
    },
  ];

  competitionsFormDatesListFields: any[] = [
    {
      label: 'Priority registration (close)',
      formControlName: 'priority_registration_close',
    },
    {
      label: 'Regular registration (close)',
      formControlName: 'regular_registration_close',
    },
    {
      label: 'Late registration (close)',
      formControlName: 'late_registration_close',
    },
  ];
  roundDescriptionListFields: any[] = [
    {
      label: 'Round 1',
      formControlName: 'instructions_1',
    },
    {
      label: 'Round 2',
      formControlName: 'instructions_2',
    },
    {
      label: 'Round 3',
      formControlName: 'instructions_3',
    },
  ];

  metaTagsTitle: { url: string; title: string }[] = [
    {
      url: 'organisation-admin-login',
      title: 'AlgoEd Organization | Login',
    },
    {
      url: 'organisation/app-shell/dashboard',
      title: 'AlgoEd Organization | Dashboard',
    },
    {
      url: 'organisation/app-shell/media-details/US',
      title: 'AlgoEd Organization | Insider Strategies',
    },
    {
      url: 'organisation/app-shell/media-details/UK',
      title: 'AlgoEd Organization | Insider Strategies',
    },
    {
      url: 'organisation/app-shell/US',
      title: 'AlgoEd Organization | Insider Strategies Lesson',
    },
    {
      url: 'organisation/app-shell/UK',
      title: 'AlgoEd Organization | Insider Strategies Lesson',
    },
    {
      url: 'organisation/app-shell/invite-students',
      title: 'AlgoEd Organization | Manage Students',
    },
    {
      url: 'organisation/app-shell/group-student',
      title: 'AlgoEd Organization | Group Students',
    },
    {
      url: 'organisation/app-shell/assign-task',
      title: 'AlgoEd Organization | Assign Task',
    },
    {
      url: 'organisation/app-shell/organisation-settings',
      title: 'AlgoEd Organization | Settings',
    },
    {
      url: 'login',
      title: 'AlgoEd Student | Login',
    },
    {
      url: 'signup',
      title: 'AlgoEd Student | Sign Up',
    },
    {
      url: 'student/admission/US/dashboard',
      title: 'AlgoEd Student | Dashboard',
    },
    {
      url: 'student/admission/UK/dashboard',
      title: 'AlgoEd Student | Dashboard',
    },
    {
      url: 'student/admission/US/tipsAndAdvice',
      title: 'AlgoEd Student | Insider Strategies Lesson',
    },
    {
      url: 'student/admission/UK/tipsAndAdvice',
      title: 'AlgoEd Student | Insider Strategies Lesson',
    },
    {
      url: 'student/admission/US/media-details',
      title: 'AlgoEd Student | Insider Strategies',
    },
    {
      url: 'student/admission/UK/media-details',
      title: 'AlgoEd Student | Insider Strategies',
    },
    {
      url: 'student/competitions/startup',
      title: 'AlgoEd Student | Competitions',
    },
    // {
    //   url: 'student/admission/US/doc-review',
    //   title: 'AlgoEd Student | Essay Review',
    // },
    // {
    //   url: 'student/admission/UK/doc-review',
    //   title: 'AlgoEd Student | Essay Review',
    // },
    {
      url: 'student/admission/UK/question-bank',
      title: 'AlgoEd Student | Question Bank',
    },
    {
      url: 'student/personal-information',
      title: 'AlgoEd Student | Personal Info',
    },
    {
      url: 'explore-competitions',
      title: 'AlgoEd | Explore Competitions',
    },
    {
      url: 'public/competitions',
      title: 'AlgoEd | Competitions',
    },
    {
      url: 'public/doc-review',
      title: 'AlgoEd | Essay Review',
    },
    {
      url: 'public/student-videos/US',
      title: 'AlgoEd Student | Insider Strategies Lesson',
    },
    {
      url: 'public/student-videos/UK',
      title: 'AlgoEd Student | Insider Strategies Lesson',
    },
  ];

  TITLES = {
    ADMIN_LOGIN: 'AlgoEd Admin | Login',
    ORG_ADMIN_LOGIN: 'AlgoEd Organization | Login',
  };

  DESCRIPTIONS = {
    COMPETITIONS:
      'AlgoEd is a platform that hosts curated, prestigious competitions for middle and high school students.',
    STUDENT_VIDEOS:
      'University admissions insider strategies condensed into insightful 5-minute lessons.',
    DOC_REVIEW: 'Essay edits from top PhD writers.',
    DEFAULT:
      'AlgoEd is a platform that hosts curated competition and research opportunities for middle and high school students.',
  };

  public whenList = [
    {
      name: 'Before',
    },
    {
      name: 'After',
    },
  ];

  public slugList = [
    {
      name: 'Round_1_start',
    },
    {
      name: 'Round_1_end',
    },
    {
      name: 'Round_2_start',
    },
    {
      name: 'Round_2_end',
    },
    {
      name: 'Round_3_start',
    },
    {
      name: 'Round_3_end',
    },
    {
      name: 'Competition_priority_registration_close',
    },
    {
      name: 'Competition_regular_registration_close',
    },
    {
      name: 'Competition_late_registration_close',
    },
  ];

  public userList = [
    { name: 'Registered users' },
    { name: 'Paid users' },
    { name: 'Unpaid users' },
    { name: 'Disqualified users' },
    { name: 'Disqualified users Round 1' },
    { name: 'Disqualified users Round 2' },
    { name: 'Disqualified users Round 3' },
    { name: 'Teams without submissions before Round 1 ends' },
    { name: 'Teams without submissions before Round 2 ends' },
    { name: 'Teams without submissions before Round 3 ends' },
    { name: 'Teams without submissions after Round 1 ends' },
    { name: 'Teams without submissions after Round 2 ends' },
    { name: 'Teams without submissions after Round 3 ends' },
    { name: 'Teams with submissions Round 1' },
    { name: 'Teams with submissions Round 2' },
    { name: 'Teams with submissions Round 3' },
    { name: 'Teams qualified Round 1' },
    { name: 'Teams qualified Round 2' },
    { name: 'Teams qualified Round 3' },
    { name: 'Teams awarded Round 1' },
    { name: 'Do Not Meet Team Size' },
    { name: 'All Users' },
  ];
}

export const mainConstants = new MainConstants();
