<div [formGroup]="studentForm">
  <button class="modal-custom-btn-close" (click)="closeDialog()">
    <span class="pi pi-times"></span>
  </button>
  <div class="mt-3">
    <p-dropdown
      [options]="nextSixYears"
      formControlName="current_grade"
      optionLabel="gradeByYear"
      optionValue="grade"
      [appendTo]="'body'"
      placeholder="Select your current grade/year"
    ></p-dropdown>
  </div>
  <!-- Ends: gradYear -->
  <!-- Parent's Email -->
  <div class="mt-3 form-group">
    <input
      type="text"
      formControlName="parentEmailId"
      pInputText
      placeholder="Enter your parent’s email"
    />
  </div>
  <div class="mt-3 d-flex justify-content-end">
    <p-button
      type="submit"
      label="Submit"
      class="p-button-raised display-block p-button-lg my-0"
      (click)="onSubmitDetails()"
    ></p-button>
  </div>
</div>
