<div class="card h-100">
  <div class="card-body overflow-auto">
    <div>
      <h3 class="m-0 page-card-title mb-3">Filter Students</h3>
      <!-- form -->
      <form
        class="row gy-3 gx-2 mb-3 border-bottom pb-3"
        [formGroup]="studentsFilter"
        (ngSubmit)="filterStudentList()"
      >
        <div class="col-12 col-lg-6">
          <input
            type="text"
            pInputText
            placeholder="Name"
            formControlName="name"
          />
        </div>
        <div class="col-12 col-lg-6">
          <input
            type="text"
            pInputText
            placeholder="Email Id"
            formControlName="email"
          />
        </div>
        <div class="col-12 col-lg-6">
          <p-dropdown
            class="w-100 full-width"
            formControlName="gradYear"
            placeholder="Select a graduation year"
            [filter]="true"
            [virtualScrollItemSize]="25"
            [options]="[
              2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027
            ]"
            [appendTo]="'body'"
          >
          </p-dropdown>
        </div>
        <div class="col-12 col-lg-6">
          <!-- <p-dropdown
            class="w-100 full-width"
            formControlName="filterGroups"
            placeholder="Filter by Groups"
            [filter]="true"
            [virtualScrollItemSize]="25"
            optionLabel="name"
            [options]="selectedStudents"
            [optionValue]="'name'"
            [appendTo]="'body'"
          >
          </p-dropdown> -->
        </div>
        <div class="col-12 col-lg-6">
          <div class="d-flex gap-2">
            <p-button
              class="custom-ternary text-nowrap"
              label="Filter"
              type="submit"
            ></p-button>
            <button
              pButton
              class="p-primary"
              label="Clear Filter"
              (click)="clearFilters()"
            ></button>
          </div>
        </div>
      </form>
      <!-- form -->

      <!-- student data -->
      <h3 class="m-0 page-card-title mb-3">Student Data</h3>
      <ng-container *ngIf="studentList && studentList.length == 0">
        <div class="text-center">
          <img
            src="../../../../assets/images/no-students.svg"
            alt=""
            srcset=""
          />
        </div>
      </ng-container>
      <ng-container *ngIf="studentList.length > 0">
        <p-table
          [value]="studentList"
          [(selection)]="selectedStudents"
          dataKey="id"
          [paginator]="true"
          [rows]="5"
          [showCurrentPageReport]="true"
          [totalRecords]="totalRecords"
          [rowsPerPageOptions]="[2, 5, 10, 25, 50]"
          [lazy]="true"
          (onLazyLoad)="onPage($event)"
          [paginatorDropdownAppendTo]="'body'"
          currentPageReportTemplate="Showing {first} to {last} of {{
            totalRecords
          }} entries"
          responsiveLayout="stack"
          [breakpoint]="'768px'"
        >
          <ng-template pTemplate="caption">
            <div class="d-flex justify-content-between align-items-center">
              <div>{{ groupDetails?.name | titlecase }}</div>
              <button
                pButton
                class="p-primary"
                type="submit"
                (click)="updateStudents()"
              >
                Update Students
              </button>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th style="width: 4rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th>Name</th>
              <th>Email</th>
              <th>Grad year</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-student let-rowIndex="rowIndex">
            <tr>
              <td>
                <p-tableCheckbox [value]="student"></p-tableCheckbox>
              </td>
              <td>{{ student.User.firstName }}</td>
              <td>{{ student.User.email }}</td>
              <td>{{ student.User.gradYear ?? "-" }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">No records found</td>
            </tr>
          </ng-template>
        </p-table>
      </ng-container>
    </div>
  </div>
</div>
