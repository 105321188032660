import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, catchError, map, of } from 'rxjs';
import { CommonServiceService } from './common-service.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private commonService: CommonServiceService,
    private router: Router
  ) {}

  baseURL: string = environment.apiUrl;

  //validate or generate token
  generateValidateToken(object: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}generateAndValidateUserToken`,
      object
    );
  }

  //validate user token
  verifyAuth(token: string): Observable<any> {
    return this.http.get(`${this.baseURL}common/profile`, {
      headers: { 'x-auth-token': token },
    });
  }

  getResetLink(data: any): Observable<any> {
    return this.http.post(`${this.baseURL}org/forgot`, data);
  }
  verifyToken(token: string): Observable<boolean> {
    return this.verifyAuth(token).pipe(
      map((item) => {
        if (item.data.roleId == 2 && !item.data.isVerifiedEmail) {
          this.router.navigate(['/signup']);
          return false;
        } else {
          return true;
        }
      }),
      catchError(() => {
        this.commonService.logout();
        this.router.navigate(['/login']);
        return of(false);
      })
    );
  }

  handleStudentRoute(token: string, currentUser: any): Observable<boolean> {
    if (!token || !currentUser) {
      this.commonService.logout();
      this.router.navigate(['/login']);
      return of(false);
    }

    return this.verifyToken(token);
  }
}
