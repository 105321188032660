import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Competitions, TeamDetail } from '../../models/competions.models';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InviteTeamMemberComponent } from '../invite-team-member/invite-team-member.component';
import { PurchasePaymentComponent } from 'src/app/students/purchase-payment/purchase-payment.component';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { of } from 'rxjs';
import { SharableLinkComponent } from 'src/app/shared/components/sharable-link/sharable-link.component';
import { BulkInviteForCompetitionComponent } from '../bulk-invite-for-competition/bulk-invite-for-competition.component';
import { PaymentTermsComponent } from '../payment-terms/payment-terms.component';
import { ViewAwardListComponent } from '../view-award-list/view-award-list.component';

@Component({
  selector: 'app-my-competitions',
  templateUrl: './my-competitions.component.html',
  styleUrls: ['./my-competitions.component.scss'],
})
export class MyCompetitionsComponent {
  @HostListener('window:resize', ['$event']) onResize(event: Event): void {
    this.checkDeviceType();
  }

  @Input() myCompetition: Competitions[];

  @Output() isCLosedClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  paymentRef: any;
  ref: DynamicDialogRef;
  inviteDialogRef: DynamicDialogRef;
  imageBaseURL: string = environment.apiUrl;
  loggedInUserName: any;
  tempList: Competitions[] = [];
  teamMemberList: TeamDetail[] = [];
  isLeader: boolean;
  selectedTeamMember: TeamDetail;
  shareVideoLink: string;
  sharePopupPosition: string;
  deviceType: string;
  notificaltionModal: DynamicDialogRef;
  isRegisterationOpen: boolean = true;
  viewAwardModalref: DynamicDialogRef | undefined;
  constructor(
    private router: Router,
    public dialogService: DialogService,
    private commonService: CommonServiceService
  ) {
    this.checkDeviceType();
  }

  ngOnChanges() {
    this.tempList = [...this.myCompetition];
  }

  ngOnInit() {
    this.loggedInUserName =
      localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  }

  getTeamMembers(item: Competitions) {
    this.commonService
      .getByIdDetail('users/competitions/teams/', item.team_id)
      .subscribe(
        (res) => {
          this.teamMemberList = res?.data && res.data?.teams?.members;

          this.bulkPayment(item);
        },
        (error) => {
          this.commonService.showToast(
            error?.error?.error ?? 'Something went wrong',
            'error',
            1000
          );
        }
      );
  }

  navigateToSubmissionPage(competitionDetails: Competitions, index: number) {
    this.router.navigate([
      `student/competitions/${competitionDetails?.id}/team/${competitionDetails?.team_id}/submissions`,
    ]);
  }

  bulkPayment(item) {
    this.notificaltionModal = this.dialogService.open(
      BulkInviteForCompetitionComponent,
      {
        header: 'Select team members you want to pay for',
        data: {
          isJoinCompetition: true,
          memberList: this.teamMemberList.filter((x) => x.user_id),
        },
        closable: true,
        width: window.innerWidth > 900 ? '40vw' : '95vw',
        baseZIndex: 10000,
        styleClass: 'leader-modal',
      }
    );

    this.notificaltionModal.onClose.subscribe((res) => {
      if (res) {
        const finalObj = {
          user_id: res?.length && res.map((x) => x.user_id),
          mode: 'team',
          team_id: item?.team_id,
          name: item?.name,
          registration_stage: item?.registration_stage,
        };

        if (finalObj.user_id?.length > 0) {
          this.payForCompetition(true, finalObj);
        }
      }
    });
  }

  closeJoinCompetition(isCloseClick: boolean) {
    this.isCLosedClicked.emit(isCloseClick);
  }

  showTeamDetals(item: Competitions) {
    if (!item.team_disqualified)
      this.router.navigate([
        `student/competitions/${item.id}/team/${item.team_id}/team-details`,
      ]);
  }

  joinCompetition(item: any) {
    this.inviteDialogRef = this.dialogService.open(InviteTeamMemberComponent, {
      data: { teamID: item.team_id, memberData: item },
      closable: true,
      width: window.innerWidth > 900 ? '45vw' : '95vw',
      baseZIndex: 10000,
      styleClass: 'leader-modal not-leader-board',
    });
  }

  handleSearchResults(searchResults: any) {
    if (searchResults && searchResults.length) {
      this.myCompetition = searchResults;
      // this.tempList = searchResults;
    } else {
      this.myCompetition = [];
    }
  }

  onSearch(searchText: string) {
    if (!searchText) {
      // this.first = 0;
      // this.getCompetions();
      return of(this.tempList);
    }
    const genericString = searchText.toLowerCase();
    return of(
      this.myCompetition.filter((x) => {
        return (
          x.name.toLowerCase().includes(genericString) ||
          x.status.toLowerCase().includes(genericString)
        );
      })
    );
  }

  payForCompetition(isPayForEveryone: boolean, finalObj?: any) {
    this.paymentRef = this.commonService.show(PurchasePaymentComponent, {
      data: {
        studentData: this.loggedInUserName,
        isSubscriptionPayment: true,
        paymentFor: 'competition',
        team_id: finalObj.team_id,
        competitionID: finalObj.id,
        isPayForEveryone: isPayForEveryone,
        user_id: finalObj?.user_id,
        mode: finalObj?.mode,
        competitionDetails: finalObj,
      },
      height: '100vh',
      styleClass: 'fullscreen-modal',
      closable: false,
    });
    this.paymentRef.onClose.subscribe((item) => {
      // this.getRemainingCreditsOfUser()
    });
  }

  share(link: any) {
    this.shareVideoLink = link;
    if (navigator.share && this.deviceType === 'Mobile') {
      navigator
        .share({
          title: 'AlgoEd Student | Competitions',
          url: `${link}`,
        })
        .catch((err) => {
          console.error('Error sharing:', err);
        });
    } else {
      if (link) {
        this.ref = this.dialogService.open(SharableLinkComponent, {
          header: 'Share',
          height: '320px',
          width: '100%',
          style: { maxWidth: '600px' },
          styleClass: 'leader-modal',
          position: this.sharePopupPosition,
          contentStyle: {
            overflow: 'auto',
          },
          baseZIndex: 10000,
          data: {
            isDirectURL: true,
            video: `${link}`,
          },
        });
      } else {
        this.commonService.showToast('No Sharable Link Found', 'error', 3000);
      }
    }
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
    if (this.paymentRef) {
      this.paymentRef.close();
    }
    if (this.inviteDialogRef) {
      this.inviteDialogRef.close();
    }
    if (this.notificaltionModal) {
      this.notificaltionModal.close();
    }
    if (this.viewAwardModalref) {
      this.viewAwardModalref.close();
    }
  }

  private checkDeviceType(): void {
    const isMobile = window.innerWidth <= 768;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.sharePopupPosition = 'bottom';
    } else {
      this.sharePopupPosition = 'center';
    }
  }

  paymenTermsPopup(paymentMethod: number, item: any) {
    this.notificaltionModal = this.dialogService.open(PaymentTermsComponent, {
      baseZIndex: 10000,
      closable: true,
      width: window.innerWidth > 900 ? '40vw' : '95vw',
      styleClass: 'leader-modal',
    });

    this.notificaltionModal.onClose.subscribe((res) => {
      if (res) {
        if (paymentMethod === 1) {
          this.payForCompetition(false, item);
        } else if (paymentMethod === 2) {
          this.getTeamMembers(item);
        }
      }
    });
  }

  openPaymentTerms(event: any) {
    const { paymentMethod, item } = event;
    this.paymenTermsPopup(paymentMethod, item);
  }

  viewAwards(competitionData: Competitions) {
    let previousRounds;
    if (competitionData.status !== 'Finished') {
      previousRounds = competitionData.rounds.filter(
        (x) => x.round_number <= competitionData.current_round
      );
    } else {
      previousRounds = competitionData.rounds;
    }

    this.viewAwardModalref = this.dialogService.open(ViewAwardListComponent, {
      header: 'Competition Awards',
      width: window.innerWidth > 768 ? '50vw' : '100%',
      height: window.innerWidth > 768 ? '60vh' : '100%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      styleClass: 'student-detail-popup',
      data: {
        competitionData: competitionData,
        previousRounds: previousRounds,
      },
    });
  }
}
