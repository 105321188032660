import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SharedApiService } from '../../services/shared-api.service';
import { CommonServiceService } from '../../common-service.service';

@Component({
  selector: 'app-tag-form',
  templateUrl: './tag-form.component.html',
  styleUrls: ['./tag-form.component.scss'],
})
export class TagFormComponent {
  tagForm: FormGroup;

  constructor(
    public config: DynamicDialogConfig,
    private fb: FormBuilder,
    private sharedApiService: SharedApiService,
    private commonService: CommonServiceService,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    console.log(this.config.data);

    this.tagForm = this.buildForm();

    if (this.config.data && this.config.data.tag) {
      this.tagForm.patchValue(this.config.data.tag);
    }
    if (this.config.data && this.config.data.countryShortCode) {
      this.tagForm.patchValue({
        countryShortCode: this.config.data.countryShortCode,
      });
    }
  }

  buildForm(): FormGroup {
    return this.fb.group({
      id: [],
      tag_name: [''],
      countryShortCode: [''],
    });
  }

  saveTag() {
    const data = {
      isCategorList: true,
      name: {
        tag_name: this.tagForm.value.tag_name,
        countryShortName: this.tagForm.value.countryShortCode,
      },
    };

    const service = this.sharedApiService as SharedApiService;
    const methodName = this.tagForm.value.id ? 'updateRecord' : 'saveRecord';
    const path = this.tagForm.value.id
      ? `questions/tags/${this.tagForm.value.id}`
      : 'questions/tags';
    service[methodName](path, data).subscribe(
      (res) => {
        this.commonService.showToast('Tag saved successfully', 'success', 1000);
        this.ref.close();
      },
      (error) => {
        this.commonService.showToast(error.error.error, 'error', 3000);
      }
    );
  }
}
