import { Component } from '@angular/core';

@Component({
  selector: 'app-non-user-footer',
  templateUrl: './non-user-footer.component.html',
  styleUrls: ['./non-user-footer.component.scss']
})
export class NonUserFooterComponent {

}
