<section class="d-flex flex-column h-100 overflow-hidden">
  <header class="d-flex align-items-center justify-content-between gap-5 p-3">
    <div
      class="d-flex align-items-center gap-5"
      [ngClass]="{ 'justify-content-between w-100': isMobileView }"
    >
      <figure class="d-flex m-0">
        <img
          src="../../assets/icons/logo-2.svg"
          class="img-fluid brand-logo"
          alt="Logo"
        />
      </figure>
      <label
        class="menu-bar-label d-flex cursor-pointer"
        for="exploreMainMenu"
        *ngIf="isMobileView"
      >
        <span class="pi pi-bars"></span>
      </label>
    </div>

    <div
      class="d-none d-lg-flex gap-3 align-items-center justify-content-between flex-grow-1"
      *ngIf="!isMobileView"
    >
      <ul class="d-flex gap-5 p-0 align-items-center">
        <li class="explore-page-item">
          <a href="https://www.algoed.co/"> Home </a>
        </li>
        <li class="explore-page-item">
          <a href="https://www.algoed.co/about-us"> About Us </a>
        </li>
      </ul>
      <div class="d-flex gap-4 align-items-center">
        <p-button [routerLink]="['/login']" class="d-flex" [link]="true">
          <span class="link-dark">Log in</span>
        </p-button>
        <p-button
          class="custom-ternary"
          [rounded]="true"
          [routerLink]="['/signup']"
          >Sign up for free</p-button
        >
      </div>
    </div>
  </header>

  <main class="explore-content">
    <div class="flex-grow-1 pb-4">
      <!-- Explore Page Banner -->
      <div class="explore-page-banner">
        <div class="container">
          <div
            class="d-flex flex-column flex-lg-row align-items-center justify-content-lg-between gap-4 py-4"
          >
            <h6 class="fw-bold m-0">
              Competitions for Middle and High School Students
            </h6>
            <!-- Newest competitions subscription -->
            <div class="newest-updates-card-wrapper">
              <div class="card newest-updates-card" *ngIf="!isLoggedInUser">
                <div class="card-body d-flex flex-column gap-3">
                  <form
                    [formGroup]="subscriptionForm"
                    class="d-flex align-items-center align-items-lg-stretch flex-lg-column gap-3"
                  >
                    <div class="flex-grow-1">
                      <input
                        formControlName="email"
                        type="email"
                        class="p-inputtext-md"
                        placeholder="Enter your email"
                        pInputText
                      />
                    </div>
                    <div class="flex-shrink-0">
                      <button
                        class="btn btn-primary w-100 rounded fw-bold"
                        (click)="subscribeToCompetition()"
                      >
                        Get Updates
                      </button>
                    </div>
                    <div
                      class="form-control-divider"
                      *ngIf="!isMobileView"
                    ></div>

                    <!-- Share This Page Btn -->
                    <div class="flex-shrink-0" *ngIf="!isMobileView">
                      <button
                        class="btn btn-outline-primary w-100 rounded fw-bold"
                        (click)="openSharePage($event)"
                      >
                        <span>Share This Page</span>
                        <span class="icon icon-share-icon ms-2"></span>
                      </button>
                    </div>
                    <!-- ENd: Share This Page Btn -->
                  </form>
                </div>
              </div>
            </div>
            <!-- End: Newest competitions subscription -->
          </div>
        </div>
      </div>
      <!-- End: Explore Page Banner -->

      <div class="container">
        <div
          class="d-flex align-items-center justify-content-between gap-4 my-4"
        >
          <!-- Search Bar -->
          <div class="p-inputgroup search-inputgroup">
            <input
              type="text"
              [(ngModel)]="searchText"
              (ngModelChange)="filteredCompetitions()"
              pInputText
              placeholder="Search competitions"
            />
            <span class="search-icon-with-dropdown">
              <i class="pi pi-search"></i>
            </span>
          </div>
          <!-- End: Search Bar -->
          <!-- share page button for mobile view  -->
          <div class="d-lg-none ms-auto" *ngIf="isMobileView">
            <button
              class="btn btn-page-share"
              type="button"
              (click)="openSharePage($event)"
            >
              <span>Share</span>
              <span class="icon icon-share-icon"> </span>
            </button>
          </div>
          <!-- End: share page button for mobile view  -->
        </div>
        <div
          class="mb-4"
          [ngClass]="{
            'd-flex justify-content-between align-items-center': isMobileView
          }"
        >
          <!-- Title -->
          <h6 class="m-0 competition-heading">Competitions</h6>
          <!-- ENd: Title -->
        </div>
        <div
          class="d-flex flex-column-reverse flex-lg-row co-detail-card-wrapper"
        >
          <!-- <app-co-detail-card
          class="flex-grow-1"
          [competitions]="clubbedCompetitionsList"
          [isRegisterationOpen]="true"
          [ngClass]="{ 'public-competition-card-wrapper': !isLoggedInUser }"
          *ngIf="clubbedCompetitionsList?.length > 0"
        ></app-co-detail-card> -->

          <!-- Start: Explore Page Card -->
          <app-explore-page-cards
            class="flex-grow-1"
            [competitions]="clubbedCompetitionsList"
            [isRegisterationOpen]="true"
            *ngIf="clubbedCompetitionsList?.length > 0"
          ></app-explore-page-cards>
          <!-- End: Explore Page Card -->

          <!-- No Competition  -->
          <div
            *ngIf="!clubbedCompetitionsList?.length"
            class="dashboard-competitions-card card w-100 d-flex flex-column justify-content-center align-items-center gap-3 p-4"
          >
            <figure class="algoed-gif m-0 text-center">
              <img
                src="../../assets/images/explore-page/algoed-animation.gif"
                alt="Competition Trophy Image"
                class="img-fluid mx-auto"
              />
            </figure>
            <h5
              class="join-competition-text text-center text-decoration-none mb-0 mt-0"
            >
              Loading prestigious competitions…
            </h5>
          </div>
          <!-- End: No Competition -->
        </div>
      </div>
    </div>

    <footer class="bg-primary p-3 p-lg-4">
      <div class="container">
        <ul
          class="d-flex flex-column flex-lg-row justify-content-md-between align-items-md-center gap-4"
        >
          <li>
            <a
              class="text-white"
              href="https://www.algoed.co/legal/privacy-policy"
              target="_blank"
              >PRIVACY POLICY</a
            >
          </li>
          <li>
            <a
              class="text-white"
              href="https://www.algoed.co/legal/terms-conditions"
              target="_blank"
              >TERMS OF SERVICE</a
            >
          </li>
          <li class="text-white">©AlgoEd 2024</li>
        </ul>
      </div>
    </footer>
  </main>
</section>

<!-- Share Page Items for Mobile View -->
<app-share-page-card
  *ngIf="isSharePageOpen"
  [isSharePageOpen]="isSharePageOpen"
  (closeShareModal)="closeSharePage($event)"
></app-share-page-card>
<!-- End: Share Page Items for Mobile View -->

<!-- Sidemenu for mobile -->
<input
  type="checkbox"
  id="exploreMainMenu"
  *ngIf="isMobileView"
  [checked]="isCheckboxChecked"
  [(ngModel)]="isCheckboxChecked"
/>
<div class="sidemenu-container explore-main-menu" *ngIf="isMobileView">
  <label class="explore-overlay" for="exploreMainMenu"></label>
  <div class="explore-side-menu">
    <div class="d-flex menu-close-icon justify-content-end p-3">
      <label class="menu-bar-label cursor-pointer" for="exploreMainMenu">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
            stroke="#949596"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.17 14.8299L14.83 9.16992"
            stroke="#949596"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.83 14.8299L9.17 9.16992"
            stroke="#949596"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </label>
    </div>
    <ul class="sidemenu-list overflow-auto px-3">
      <li class="sidemenu-item explore-page-item">
        <a
          class="sidemenu-link"
          href="https://www.algoed.co/"
          (click)="closeSidebar()"
        >
          Home
        </a>
      </li>
      <li class="sidemenu-item explore-page-item">
        <a
          class="sidemenu-link"
          href="https://www.algoed.co/about-us"
          (click)="closeSidebar()"
        >
          About Us
        </a>
      </li>
      <li class="sidemenu-item explore-page-item">
        <a
          class="sidemenu-link"
          [routerLink]="['/login']"
          (click)="closeSidebar()"
        >
          Log in
        </a>
      </li>
    </ul>

    <div class="p-3">
      <button
        class="btn btn-ternary text-white fw-bold"
        [rounded]="true"
        [routerLink]="['/signup']"
      >
        Sign up for free
      </button>
    </div>
  </div>
</div>
