import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class DecryptService {
  key = 'algoedkeyencryption03032022CNPL0';
  iv = 'algoedkeyencrypt';

  constructor() {}

  encrypt(data: any, isCategorySection: boolean = true): any {
    if (isCategorySection) {
      try {
        let input = JSON.stringify(data);
        let cipher = CryptoJS.AES.encrypt(
          input,
          CryptoJS.enc.Utf8.parse(this.key),
          {
            iv: CryptoJS.enc.Utf8.parse(this.iv),
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
          }
        );
        const output = cipher.toString();
        var rdata = {};
        rdata['data'] = output;
        // ;
        return rdata;
      } catch (e) {}
    }
  }

  decrypt(textToDecrypt: string) {
    var decrypted = CryptoJS.AES.decrypt(
      textToDecrypt,
      CryptoJS.enc.Utf8.parse(this.key),
      {
        iv: CryptoJS.enc.Utf8.parse(this.iv),
      }
    );
    let output = decrypted.toString(CryptoJS.enc.Utf8);
    return output;
  }
}
