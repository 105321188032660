import { Pipe, PipeTransform } from '@angular/core';
import { Competitions } from '../models/competions.models';

@Pipe({
  name: 'pendingCount',
})
export class PendingCountPipe implements PipeTransform {
  transform(competitionItem: Competitions | null): number {
    if (!competitionItem) {
      return 0;
    }

    let count = 0;

    if (competitionItem.payment_status !== 'Paid') {
      count++;
    }

    if (
      competitionItem.individual !== 1 &&
      competitionItem.required_team_members - competitionItem.member_count > 0
    ) {
      count++;
    }

    if (
      competitionItem.individual !== 1 &&
      competitionItem.member_paid_count !== competitionItem.member_count
    ) {
      count++;
    }

    return count;
  }
}
