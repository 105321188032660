import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortCategoryList',
})
export class SortCategoryListPipe implements PipeTransform {
  transform(value: any[], ...args: any[]): any[] {
    if (!value || !Array.isArray(value)) {
      return value;
    }
    return value.sort((a, b) => a.sequence - b.sequence);
  }
}
