<div class="invite-card card">
  <div class="card-body">
    <h5 class="text-heading-black fs-5 m-0 mb-3">Invite team members</h5>
    <div class="d-flex flex-column flex-lg-row align-items-center gap-3">
      <div class="flex-grow-1 w-100">
        <input
          class="w-100"
          type="text"
          pInputText
          id="inviteTeam"
          [(ngModel)]="emailToInvite"
          placeholder="Enter teammate's email"
        />
      </div>

      <div
        class="invite-team-footer flex-wrap d-flex align-items-center gap-3 flex-shrink-0"
      >
        <button class="btn btn-primary" (click)="inviteTeamMember()">
          Send Invite
        </button>
        <!-- <button
          class="btn btn-outline-primary d-flex align-items-center justify-content-center gap-2"
        >
          <span class="icon icon-copy-link"></span>
          <span>Copy invite link</span>
        </button> -->
      </div>
    </div>
  </div>
</div>
