<div class="join-competition-canvas overflow-hidden">
  <div class="overlay" (click)="closeJoinCompetition(false)"></div>

  <div class="canvas-content d-flex flex-column h-100 overflow-hidden">
    <header class="d-flex gap-4 p-3">
      <div class="flex-shrink-0">
        <button
          type="button"
          class="btn-close cursor-pointer fs-6 p-2"
          aria-label="Close"
          (click)="closeJoinCompetition(false)"
        ></button>
      </div>
      <div class="flex-grow-1">
        <h6 class="canvas-title m-0">My Competitions</h6>
        <p class="m-0 canvas-desc">Manage your registrations here</p>
      </div>
    </header>

    <div class="d-flex p-3">
      <!-- Search Competitions -->
      <app-search
        [searchMaxWidth]="'100%'"
        [placeholder]="'Search competition'"
        [searchFunction]="onSearch.bind(this)"
        (searchResults)="handleSearchResults($event)"
      ></app-search>
    </div>

    <!-- competitions list -->
    <div class="d-flex flex-grow-1 flex-column gap-3 p-3 overflow-auto">
      <div class="card" *ngFor="let item of myCompetition">
        <div class="card-body p-3">
          <div class="d-flex align-items-start gap-3 gap-lg-4">
            <figure class="competition-canvas-img m-0">
              <img
                [src]="
                  item.thumbnail
                    ? imageBaseURL + 'common/getfile/' + item.thumbnail
                    : '../../../../assets/images/student-portal/competition-img.png'
                "
                class="img-fluid"
                [alt]="item?.name"
              />
            </figure>
            <div class="flex-grow-1">
              <h5
                class="m-0 cursor-pointer text-decoration-underline"
                (click)="showTeamDetals(item)"
              >
                {{ item.name }}
              </h5>
              <div class="d-flex align-items-center flex-wrap gap-1 gap-md-2">
                <div class="team-name">Team Name:</div>
                <div
                  class="team-desc cursor-pointer"
                  (click)="showTeamDetals(item)"
                >
                  {{ item?.team_name || "N/A" }}
                </div>
              </div>
            </div>

            <!-- Share button  -->
            <svg
              (click)="share(item.details)"
              class="cursor-pointer"
              width="19"
              height="21"
              viewBox="0 0 19 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.25 20.0861C14.4514 20.0861 13.7726 19.8066 13.2135 19.2476C12.6545 18.6885 12.375 18.0097 12.375 17.2111C12.375 17.0993 12.383 16.9835 12.399 16.8637C12.4149 16.7439 12.4389 16.6361 12.4708 16.5403L5.71458 12.6111C5.44306 12.8507 5.13958 13.0384 4.80417 13.1741C4.46875 13.3099 4.11736 13.3778 3.75 13.3778C2.95139 13.3778 2.27257 13.0983 1.71354 12.5392C1.15451 11.9802 0.875 11.3014 0.875 10.5028C0.875 9.70416 1.15451 9.02534 1.71354 8.46631C2.27257 7.90728 2.95139 7.62777 3.75 7.62777C4.11736 7.62777 4.46875 7.69565 4.80417 7.83141C5.13958 7.96718 5.44306 8.15485 5.71458 8.39443L12.4708 4.46527C12.4389 4.36943 12.4149 4.26162 12.399 4.14183C12.383 4.02204 12.375 3.90624 12.375 3.79443C12.375 2.99582 12.6545 2.317 13.2135 1.75798C13.7726 1.19895 14.4514 0.919434 15.25 0.919434C16.0486 0.919434 16.7274 1.19895 17.2865 1.75798C17.8455 2.317 18.125 2.99582 18.125 3.79443C18.125 4.59304 17.8455 5.27186 17.2865 5.83089C16.7274 6.38992 16.0486 6.66943 15.25 6.66943C14.8826 6.66943 14.5312 6.60155 14.1958 6.46579C13.8604 6.33002 13.5569 6.14235 13.2854 5.90277L6.52917 9.83193C6.56111 9.92777 6.58507 10.0356 6.60104 10.1554C6.61701 10.2752 6.625 10.391 6.625 10.5028C6.625 10.6146 6.61701 10.7304 6.60104 10.8502C6.58507 10.97 6.56111 11.0778 6.52917 11.1736L13.2854 15.1028C13.5569 14.8632 13.8604 14.6755 14.1958 14.5397C14.5312 14.404 14.8826 14.3361 15.25 14.3361C16.0486 14.3361 16.7274 14.6156 17.2865 15.1746C17.8455 15.7337 18.125 16.4125 18.125 17.2111C18.125 18.0097 17.8455 18.6885 17.2865 19.2476C16.7274 19.8066 16.0486 20.0861 15.25 20.0861Z"
                fill="#595959"
              />
            </svg>

            <!-- End: Share button  -->
          </div>

          <!-- If Registration was not completed -->
          <div
            class="resgitration-incomplete-notification"
            *ngIf="
              item?.team_disqualified &&
              item?.team_disqualified_reason == 'Auto_Not_filled'
            "
          >
            <span class="flex-shrink-0">
              <img
                src="../../../../assets/competitions/disqualified.svg"
                class="img-fluid"
                alt="Disqualified"
                style="width: 52px"
              />
            </span>
            <p class="m-0 fs-6 unfortunately-message">
              <ng-container *ngIf="item.individual == 0; else elseTemplate">
                Unfortunately, not enough participants on your team completed
                registration by the deadline.</ng-container
              >
              <ng-template #elseTemplate>
                Unfortunately, you did not complete registration by the
                deadline.
              </ng-template>
              You do not currently qualify to participate. To change this,
              please contact
              <a href="mailto:support@algoed.co">
                <span> support<span>&#64;algoed.co</span></span></a
              >
              to see if you qualify for an exception.
            </p>
          </div>
          <!-- End: If Registration was not completed -->

          <!-- progress section -->
          <div
            class="mt-3 mt-md-4 mt-lg-5 pb-3 pb-md-4 pb-lg-5 border-bottom overflow-hidden"
            *ngIf="!item?.team_disqualified"
          >
            <!-- -- for loop here -- -->
            <div
              class="competition-progress-steps d-flex align-items-center overflow-horizontal"
            >
              <!-- Done -->
              <div class="progress-step d-flex align-items-center gap-2">
                <!-- add completed class  -->
                <div
                  class="progressBar"
                  [ngClass]="{ completed: item.status !== 'Registration' }"
                >
                  <svg class="progress-track">
                    <circle cx="20" cy="20" r="15"></circle>
                    <circle
                      cx="20"
                      cy="20"
                      r="15"
                      [style]="{
                        '--percent':
                          item
                          | calculatePercentage
                            : [
                                'payment_status',
                                'member_count',
                                'member_paid_count'
                              ]
                      }"
                    ></circle>
                  </svg>
                  <svg
                    *ngIf="item.status !== 'Registration'"
                    class="completed-tick-icon"
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 4.5L5.5 8.5L12.5 1"
                      stroke="white"
                      stroke-width="1.4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <div
                    class="progress-count text"
                    *ngIf="item.status == 'Registration'"
                  >
                    1
                  </div>
                  <div
                    class="progress-count"
                    *ngIf="item.status !== 'Registration'"
                  >
                    <svg
                      width="14"
                      height="10"
                      viewBox="0 0 14 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 4.5L5.5 8.5L12.5 1"
                        stroke="white"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div class="progress-step-text">Registration</div>
              </div>
              <!-- End: Done -->

              <!-- Competition Rounds -->
              <div
                class="progress-step d-flex align-items-center gap-2"
                *ngFor="let round of item.rounds; let roundIndex = index"
              >
                <div
                  class="progressBar"
                  [ngClass]="{
                    completed:
                      round.round_number <
                      (item | getCurrentRound : 'round_number')
                  }"
                >
                  <svg class="progress-track">
                    <circle cx="20" cy="20" r="15"></circle>

                    <circle
                      cx="20"
                      cy="20"
                      r="15"
                      [style]="{
                        '--percent': item | roundPercentage : round
                      }"
                    ></circle>
                  </svg>

                  <div
                    class="progress-count"
                    *ngIf="
                      item.current_round > 0
                        ? item?.current_round == roundIndex + 1
                        : false
                    "
                  >
                    {{ item.current_round }}
                  </div>
                  <!-- lock icon -->
                  <div class="progress-count">
                    <svg
                      *ngIf="
                        item?.current_round !== roundIndex + 1 &&
                        round.round_number >
                          (item | getCurrentRound : 'round_number')
                      "
                      width="12"
                      height="15"
                      viewBox="0 0 12 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0013 5.33073H9.33463V3.9974C9.33463 2.1574 7.8413 0.664062 6.0013 0.664062C4.1613 0.664062 2.66797 2.1574 2.66797 3.9974V5.33073H2.0013C1.26797 5.33073 0.667969 5.93073 0.667969 6.66406V13.3307C0.667969 14.0641 1.26797 14.6641 2.0013 14.6641H10.0013C10.7346 14.6641 11.3346 14.0641 11.3346 13.3307V6.66406C11.3346 5.93073 10.7346 5.33073 10.0013 5.33073ZM6.0013 11.3307C5.26797 11.3307 4.66797 10.7307 4.66797 9.9974C4.66797 9.26406 5.26797 8.66406 6.0013 8.66406C6.73463 8.66406 7.33463 9.26406 7.33463 9.9974C7.33463 10.7307 6.73463 11.3307 6.0013 11.3307ZM8.06797 5.33073H3.93463V3.9974C3.93463 2.8574 4.8613 1.93073 6.0013 1.93073C7.1413 1.93073 8.06797 2.8574 8.06797 3.9974V5.33073Z"
                        fill="#797979"
                      />
                    </svg>

                    <!-- lock icon -->
                    <svg
                      *ngIf="
                        round.round_number <
                        (item | getCurrentRound : 'round_number')
                      "
                      class="completed-tick-icon"
                      width="14"
                      height="10"
                      viewBox="0 0 14 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 4.5L5.5 8.5L12.5 1"
                        stroke="white"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div class="progress-step-text">{{ round?.round_name }}</div>
              </div>

              <!-- End: Competition Rounds -->
            </div>
          </div>
          <!-- End: progress section  -->

          <!-- Registration States -->
          <!-- *ngIf="item.status == 'Registration'" -->
          <div
            class="d-flex flex-column flex-sm-row justify-content-sm-between gap-3 py-4"
            [ngClass]="{
              'pt-4 pb-2': item.status !== 'Registration',
              'py-4': item.status == 'Registration'
            }"
            *ngIf="!item?.team_disqualified"
          >
            <div class="d-flex align-items-center gap-2">
              <h6 class="text-state-heading">
                {{ item?.status ? item?.status : "N/A" }}
              </h6>
              <div *ngIf="true">
                <span class="badge badge-success">CURRENT STAGE</span>
              </div>
              <div *ngIf="false">
                <span class="badge badge-primary">QUALIFIED</span>
              </div>
              <div *ngIf="false">
                <span class="badge badge-secondary">DISQUALIFIED</span>
              </div>
            </div>

            <!-- progress bar -->
            <div
              class="d-flex align-items-center gap-2"
              *ngIf="item?.status == 'Registration'"
            >
              <div class="progress-bar-text fw-bold">
                {{
                  item.individual == 0
                    ? (item
                        | calculatePercentage
                          : [
                              "payment_status",
                              "member_count",
                              "member_paid_count",
                              true
                            ]) + " of 3 complete!"
                    : (item.payment_status == "Paid" ||
                      item.payment_status == "Waived"
                        ? 1
                        : 0) + " of 1 complete!"
                }}
              </div>
              <div
                class="progress flex-grow-1"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div
                  class="progress-bar"
                  [ngClass]="
                    item.individual == 0
                      ? (item
                        | calculatePercentage
                          : [
                              'payment_status',
                              'member_count',
                              'member_paid_count',
                              true,
                              true
                            ])
                      : item.payment_status == 'Paid' ||
                        item.payment_status == 'Waived'
                      ? 'w-100'
                      : '0'
                  "
                ></div>
              </div>
            </div>
          </div>
          <!-- End: Registration States -->
          <!-- *ngIf="item?.rounds[item?.current_round - 1]?.fully_submitted == 1" -->
          <ng-container
            #submittedMessage
            *ngIf="
              !(
                item.status !== 'Registration' &&
                  item.team_disqualified &&
                  item.team_disqualified_round == item.current_round &&
                  !(item | getCurrentRound : 'awards') &&
                  item.team_disqualified_reason | testPartialSelect
              ) &&
              !(
                item.status !== 'Registration' &&
                  item.team_disqualified &&
                  item.team_disqualified_round == item.current_round &&
                  (item | getCurrentRound : 'awards') &&
                  item.team_disqualified_reason | testPartialSelect
              ) &&
              !(
                item.team_disqualified &&
                item.team_disqualified_round &&
                item.team_disqualified_reason == 'Round_Not_Submitted'
              ) &&
              !(
                item.status !== 'Registration' &&
                !item.team_disqualified &&
                item.team_qualified_round == item.current_round
              ) &&
              (item | isCurrentRoundSubmitted)
            "
          >
            <div
              class="submission-submitted d-flex flex-column flex-md-row align-items-md-center gap-4 mt-3"
            >
              <figure class="m-0">
                <img
                  src="../../../../assets/images/competition/submission-successful.png"
                  class="img-fluid"
                  alt="Submission Successful"
                />
              </figure>
              <p class="m-0">
                Your solution has been successfully submitted. We’ll notify you
                once the results are out. Best of luck!
              </p>
            </div>
          </ng-container>
          <!-- Registration Ends Notification -->

          <!-- Qualified Card -->
          <div
            *ngIf="
              item.status !== 'Registration' &&
              !item.team_disqualified &&
              item.team_qualified_round == item.current_round
            "
            class="mt-4 details-step-card card overflow-hidden"
          >
            <div class="card-body p-0">
              <!-- View Results -->

              <!-- Qualified Img  -->
              <img
                src="../../../../assets/images/student-portal/confetti-img.png"
                alt="confetti-img"
                height="48"
                class="w-100"
              />
              <!-- End: Qualified Img  -->

              <div class="d-flex flex-column gap-3 p-3">
                <div class="d-flex align-items-center gap-3 flex-grow-1">
                  <h6 class="card-title fs-6 m-0">
                    <span
                      >Congratulations! You have qualified for the next
                      round!</span
                    >
                  </h6>
                  <button
                    pButton
                    pRipple
                    (click)="showTeamDetals(item)"
                    type="button"
                    label="View Results"
                  ></button>
                </div>

                <!-- <div class="flex-shrink-0">
                  <button class="btn btn-danger">View Results</button>
                </div> -->
              </div>
              <!-- End: View Results -->
            </div>
          </div>
          <!-- End: Qualified Card -->

          <div
            class="registration-ends-notification mt-4"
            *ngIf="
              item?.show_late_message &&
              !item?.team_disqualified &&
              item?.status == 'Registration'
            "
          >
            <div>
              <h6 class="registration-ends-heading">
                You still haven’t met the minimum requirements for registration.
                Please finish the pending actions!
              </h6>
              <p class="registration-ends-note">
                <b>Note:</b> Your participant fee will not be refunded if
                <ng-container *ngIf="item.individual == 0; else elseTemplate">
                  your team does
                </ng-container>
                <ng-template #elseTemplate>you did</ng-template>
                not meet the minimum requirements before the deadline.
              </p>
            </div>

            <!-- countdown timer -->
            <app-countdown-timer
              class="registration-ends-timer"
              [endTime]="item?.late_registration_close"
            ></app-countdown-timer>
            <!-- End: countdown timer -->
          </div>
          <!-- End: Registration Ends Notification -->

          <!-- Round Submission Required -->
          <!-- item?.rounds[item?.current_round - 1]?.fully_submitted -->
          <div
            *ngIf="
              item?.status !== 'Registration' &&
                item?.status == 'In Progress' &&
                !item?.team_disqualified &&
                !item | getFullysubmitted
            "
            class="details-step-card card mt-3"
          >
            <div class="card-body p-0">
              <!-- Pending Actions -->
              <div class="d-flex align-items-center gap-3 p-3 border-bottom">
                <span class="d-flex align-items-center">
                  <img
                    src="../../../../assets/competitions/pending-actions.svg"
                    alt="Pending actions"
                    class="img-fluid icon-pending-actions"
                  />
                </span>
                <h6 class="card-title fs-6 m-0">
                  <span>Pending Actions</span>
                </h6>
              </div>
              <div
                class="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between gap-3 flex-wrap p-3"
              >
                <div class="d-flex align-items-center gap-3 flex-grow-1">
                  <!-- Apply *ngIf below -->
                  <!-- bordered circle icon -->
                  <svg
                    *ngIf="true"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="11.5" stroke="#D0D4E4" />
                  </svg>
                  <div>
                    <h6 class="card-title fs-6 m-0">
                      <span>Submission Required</span>
                    </h6>
                  </div>
                </div>

                <div class="flex-shrink-0">
                  <button
                    class="btn btn-join-now"
                    (click)="
                      navigateToSubmissionPage(item, item?.current_round - 1)
                    "
                  >
                    Submit Now
                  </button>
                </div>
              </div>
            </div>
            <!-- End: Pending Actions -->
          </div>
          <!-- End: Submission Required -->
          <div
            class="resgitration-incomplete-notification"
            *ngIf="
              item.team_disqualified &&
              item.team_disqualified_round &&
              item.team_disqualified_reason == 'Round_Not_Submitted'
            "
          >
            <span>
              <img
                src="../../../../assets/svgs/unfortunate.svg"
                alt=""
                srcset=""
              />
            </span>
            <p class="m-0 fs-6 unfortunately-message">
              Unfortunately, you failed to submit before the deadline. Please
              contact
              <a href="mailto:support@algoed.co">
                <span> support<span>&#64;algoed.co</span></span></a
              >
              if there has been a mistake.
            </p>
            <div *ngIf="item?.has_awards" class="flex-shrink-0">
              <button
                class="btn btn-primary rounded-pill"
                (click)="viewAwards(item)"
              >
                View Award
              </button>
            </div>
          </div>

          <div
            *ngIf="
              item.status !== 'Registration' &&
                item.team_disqualified &&
                item.team_disqualified_round &&
                (item
                  | getPreviousRoundDetails : item.team_disqualified_round) &&
                item.team_disqualified_reason | testPartialSelect
            "
            class="mt-4 disqualified-but-awarded-notification d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between gap-4"
          >
            <span class="m-0">
              Congratulations! You received an award! Your team will not proceed
              to the next round.
            </span>
            <div *ngIf="item?.has_awards" class="flex-shrink-0">
              <button
                class="btn btn-primary rounded-pill"
                (click)="viewAwards(item)"
              >
                View Award
              </button>
            </div>
          </div>
          <div
            *ngIf="
              item.status !== 'Registration' &&
                item.team_disqualified &&
                item.team_disqualified_round &&
                !(
                  item | getPreviousRoundDetails : item.team_disqualified_round
                ) &&
                item.team_disqualified_reason | testPartialSelect
            "
            class="mt-4 disqualified-but-awarded-notification"
          >
            <span class="m-0">
              Thank you for your submission. Your team will not proceed to the
              next round.
            </span>
            <div *ngIf="item?.has_awards" class="flex-shrink-0">
              <button
                class="btn btn-primary rounded-pill"
                (click)="viewAwards(item)"
              >
                View Award
              </button>
            </div>
          </div>

          <ng-container *ngIf="item.status == 'Registration'">
            <app-registration-stages
              [competitionItem]="item"
              (competition)="joinCompetition($event)"
              (paymentModeDetails)="openPaymentTerms($event)"
            ></app-registration-stages>
          </ng-container>

          <!-- Round 1 States -->
          <div
            *ngIf="false"
            class="d-flex flex-column flex-sm-row justify-content-sm-between gap-3 py-4"
          >
            <div class="d-flex align-items-center gap-2">
              <h6 class="text-state-heading">Round 1</h6>
              <div>
                <span class="badge badge-success">CURRENT STAGE</span>
              </div>
              <div>
                <span class="badge badge-primary">QUALIFIED</span>
              </div>
              <div>
                <span class="badge badge-secondary">DISQUALIFIED</span>
              </div>
            </div>

            <!-- progress bar -->
            <div class="d-flex align-items-center gap-2">
              <div class="progress-bar-text fw-bold">1 of 3 complete!</div>
              <div
                class="progress flex-grow-1"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div class="progress-bar w-75"></div>
              </div>
            </div>

            <!-- End: progress bar -->
          </div>
          <!-- End: Round 1 States -->

          <!-- Round 1 View Submission -->
          <div *ngIf="false" class="details-step-card card">
            <div class="card-body p-0">
              <!-- View Submission -->
              <div
                class="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between gap-3 flex-wrap p-3"
              >
                <div class="d-flex align-items-center gap-3 flex-grow-1">
                  <!-- Apply *ngIf below -->
                  <!-- bordered circle icon -->
                  <svg
                    *ngIf="false"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="11.5" stroke="#D0D4E4" />
                  </svg>
                  <!-- Search icon -->
                  <svg
                    *ngIf="true"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12.4922"
                      cy="12.4922"
                      r="12.4922"
                      fill="#D0E6FD"
                    />
                    <path
                      d="M18.2166 18.1719L13.8105 13.7748C13.4887 14.0499 13.1074 14.2607 12.6666 14.407C12.2257 14.5534 11.7582 14.6266 11.2638 14.6266C10.0336 14.6266 8.99201 14.1999 8.13896 13.3466C7.2859 12.4932 6.85938 11.4638 6.85938 10.2585C6.85938 9.05318 7.28454 8.0237 8.13488 7.17008C8.98522 6.31646 10.0173 5.88965 11.2313 5.88965C12.4452 5.88965 13.4762 6.3165 14.3242 7.1702C15.1723 8.02389 15.5964 9.05447 15.5964 10.2619C15.5964 10.7442 15.5233 11.2014 15.3772 11.6337C15.231 12.0659 15.0146 12.472 14.7279 12.852L19.1511 17.2375L18.2166 18.1719ZM11.2479 13.348C12.1052 13.348 12.8312 13.0475 13.4258 12.4465C14.0204 11.8454 14.3177 11.1157 14.3177 10.2571C14.3177 9.39863 14.0199 8.66919 13.4244 8.06883C12.8288 7.46847 12.1019 7.16828 11.2437 7.16828C10.3762 7.16828 9.64169 7.46879 9.04023 8.0698C8.43876 8.67082 8.13803 9.40059 8.13803 10.2591C8.13803 11.1176 8.43852 11.8471 9.03953 12.4474C9.64054 13.0478 10.3766 13.348 11.2479 13.348Z"
                      fill="#0273EA"
                    />
                  </svg>

                  <div>
                    <h6 class="card-title fs-6 m-0">
                      <span>Your submission is being reviewed</span>
                    </h6>
                  </div>
                </div>

                <div class="flex-shrink-0">
                  <button class="btn btn-join-now">View Submission</button>
                </div>
              </div>
              <!-- End: View Submission -->
            </div>
          </div>

          <!-- Round1 DISQUALIFIED Details Card -->
          <div *ngIf="false" class="details-step-card card">
            <div class="card-body p-0">
              <!-- View Results -->
              <div class="d-flex flex-column gap-3 flex-wrap p-3">
                <div class="d-flex align-items-center gap-3 flex-grow-1">
                  <!-- Apply *ngIf below -->

                  <div>
                    <h6 class="card-title fs-6 m-0">
                      <span
                        >Thank you for your submission. Your team will not
                        proceed to the next round.</span
                      >
                    </h6>
                  </div>
                </div>

                <div class="flex-shrink-0">
                  <button class="btn btn-join-now">View Results</button>
                </div>
              </div>
              <!-- End: View Results -->
            </div>
          </div>
          <!-- End: Round1 DISQUALIFIED Details Card -->
          <!-- End: Round1  Details Card -->
        </div>
      </div>
      <ng-container *ngIf="myCompetition?.length === 0">
        <div class="text-center text-danger">No competitions found</div>
      </ng-container>
    </div>
    <!-- End: competitions list -->
  </div>
</div>
