<p-card>
  <div class="my-2">
    <label for="superAdminUSUK" class="form-label"> US or UK</label>
    <p-dropdown
      [options]="['US', 'UK']"
      [(ngModel)]="superAdminUSUK"
      placeholder="US or UK"
      [appendTo]="'body'"
      (ngModelChange)="getTagsList()"
      class="d-block w-100"
    ></p-dropdown>
  </div>
  <p-table [value]="tagList">
    <ng-template pTemplate="caption">
      <p-button
        label="Add Tags"
        icon="pi pi-tag"
        [outlined]="true"
        [size]="'small'"
        (click)="openTagsForm(false)"
      ></p-button>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Sr no.</th>
        <th>Tag Name</th>
        <th>Created At</th>
        <th>Country</th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-tag let-i="rowIndex">
      <tr>
        <td>{{ i + 1 }}</td>
        <td>{{ tag.tag_name }}</td>
        <td>{{ tag.createdAt | date : "dd-MM-yyyy" }}</td>
        <td>{{ tag.countryShortName }}</td>
        <td>
          <span class="cursor-pointer" (click)="openTagsForm(true, tag)">
            <i class="pi pi-pencil"></i>
          </span>
          <span
            class="ms-4 cursor-pointer text-danger"
            (click)="deleteTag(tag)"
          >
            <i class="pi pi-trash"></i>
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
