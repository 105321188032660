import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminService } from '../admin.service';
import { CommonServiceService } from '../common-service.service';
import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SharedMethodsService {
  days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
  months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  constructor(
    private commonService: CommonServiceService,
    public adminService: AdminService,
    private router: Router
  ) {}

  /**
   * Appends the timezone offset to a given datetime string.
   *
   * @param {string} datetimeStr - The datetime string to append the timezone offset to.
   * @return {string} The datetime string with the timezone offset appended.
   */
  appendTimezone(datetimeStr, timeZoneOffset) {
    const date = new Date(datetimeStr);

    const pad = (num) => num.toString().padStart(2, '0');

    const dateTime =
      `${pad(date.getMonth() + 1)}/${pad(
        date.getDate()
      )}/${date.getFullYear()} ` +
      `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
        date.getSeconds()
      )}.${pad(date.getMilliseconds())}`;
    const timezoneOffset = date.getTimezoneOffset();
    const diff = timezoneOffset > 0 ? '-' : '+';

    return `${dateTime}${timeZoneOffset}`;
  }

  // convertToTimeZone(dateString, offset) {
  //   // Parse the input date string as a UTC date
  //   let date = new Date(dateString);

  //   // Calculate the time difference in milliseconds
  //   let timeOffsetInMs = offset * 60 * 60 * 1000;

  //   // Apply the offset to the UTC date
  //   let convertedDate = new Date(date.getTime() + timeOffsetInMs);

  //   // Return the converted date as a string
  //   return convertedDate.toISOString().replace('Z', '');
  // }

  convertToTimeZone(dateString, offset) {
    // Parse the input date string as a UTC date
    let date = new Date(dateString);

    // Calculate the time difference in milliseconds
    let timeOffsetInMs = offset && this.parseOffset(offset);

    // Apply the offset to the UTC date
    let convertedDate = new Date(date.getTime() + timeOffsetInMs);

    // Return the converted date as a string
    return convertedDate.toISOString().replace('Z', '');
  }

  parseOffset(offset) {
    // Extract the sign, hours, and minutes from the offset string
    let match = offset.match(/^([+-])(\d{1,2})(?::(\d{2}))?$/);
    if (!match) {
      console.log('No match found');
      return 0;
    }
    let sign = match[1] === '+' ? 1 : -1;
    let hours = parseInt(match[2], 10);
    let minutes = match[3] ? parseInt(match[3], 10) : 0;

    // Convert the total offset to milliseconds
    return sign * ((hours * 60 + minutes) * 60 * 1000);
  }

  getNameByControlName(controlName: string) {
    switch (controlName) {
      case 'name':
        return 'Please enter Competition name';
      case 'competition_type':
        return 'Please select Competition type';
      case 'detail':
        return 'Please enter URL';
      case 'required_team_members':
        return 'Please enter number of required team members';
      case 'max_team_members':
        return 'Please enter number of max team members';
      case 'priority_registration_close':
        return 'Please enter priority registration close date';
      case 'regular_registration_close':
        return 'Please enter regular registration close date';
      case 'late_registration_close':
        return 'Please enter late registration close date';
      case 'priority_registration_price':
        return 'Please enter priority registration price';
      case 'regular_registration_price':
        return 'Please enter regular registration price';
      case 'late_registration_price':
        return 'Please enter late registration price';
      case 'thumbnailFile':
        return 'Please select image';
      case 'rounds':
        return 'Please add rounds';
      case 'title':
        return 'Please enter title';
      case 'desktopImage':
        return 'Please select desktop image';
      case 'mobileImage':
        return 'Please select mobile image';
      case 'content':
        return 'Please enter link';
      default:
        return 'Please enter required fields';
    }
  }

  convertCompetitionFormat(data) {
    let converted = {
      name: data?.name ?? '',
      competition_type: data?.competition_type ?? '',
      registration_stage: data?.registration_stage,
      status: data?.status,
      details: data?.details,
      required_team_members: data?.required_team_members,
      max_team_members: data?.max_team_members,
      subject: data.subject,
      format: data.format,
      category: data.category,
      single_score_to_consider: data.single_score_to_consider,
      single_user: data.single_user ? 1 : 0,
      prelims:
        data.prelims && this.appendTimezone(data.prelims, data.timezone_offset),
      final:
        data.final && this.appendTimezone(data.final, data.timezone_offset),
      priority_registration_close:
        data?.priority_registration_close &&
        this.appendTimezone(
          data?.priority_registration_close,
          data?.timezone_offset
        ),
      regular_registration_close:
        data?.regular_registration_close &&
        this.appendTimezone(
          data?.regular_registration_close,
          data?.timezone_offset
        ),
      late_registration_close:
        data?.late_registration_close &&
        this.appendTimezone(
          data?.late_registration_close,
          data?.timezone_offset
        ),
      priority_registration_price: data?.priority_registration_price,
      regular_registration_price: data?.regular_registration_price,
      late_registration_price: data?.late_registration_price,
      thumbnailFile: data?.thumbnailFile,
      loginFileDesktop: data?.loginFileDesktop,
      loginFileMobile: data?.loginFileMobile,
      individual: data.individual ? 1 : 0,
      show_sample_question: data?.show_sample_question ? 1 : 0,
      timezone_offset: data?.timezone_offset,
      org_id: data?.org_id,
      rolling_submission: data?.rolling_submission ? 1 : 0,
    };

    data.static_sample_question &&
      data.static_sample_question.forEach((question, i) => {
        converted[`sample_questions[${i}][question_text]`] =
          question.question_text;
        question.staticFiles &&
          question.staticFiles.forEach((file, j) => {
            converted[`sample_questions[${i}][files][${j}]`] = file;
          });
      });
    data.staticFiles &&
      data.staticFiles.forEach((file, index) => {
        converted[`staticFiles[${index}]`] = file;
      });
    data.rounds &&
      data.rounds.rounds &&
      data.rounds.rounds.forEach((round, roundIndex) => {
        // if (roundIndex === 0) {
        //   converted[`rounds[${roundIndex}][round_start]`] =
        //     data?.late_registration_close &&
        //     this.appendTimezone(
        //       data?.late_registration_close,
        //       data?.timezone_offset
        //     );
        // } else {
        converted[`rounds[${roundIndex}][round_start]`] =
          round.round_start &&
          this.appendTimezone(round.round_start, data?.timezone_offset);
        // }
        converted[`rounds[${roundIndex}][round_end]`] =
          round.round_end &&
          this.appendTimezone(round.round_end, data?.timezone_offset);
        converted[`rounds[${roundIndex}][instructions]`] =
          round.instructions ?? '';
        converted[`rounds[${roundIndex}][single_user]`] = round.single_user
          ? 0
          : 1;
        converted[`rounds[${roundIndex}][single_score_to_consider]`] =
          (round.single_score_to_consider && +round.single_score_to_consider) ??
          null;
        round?.files &&
          round.files?.forEach((file, fileIndex) => {
            converted[`rounds[${roundIndex}][files][${fileIndex}]`] = file;
          });
        round?.prompts?.forEach((prompt, promptIndex) => {
          if (prompt) {
            converted[`rounds[${roundIndex}][prompts][${promptIndex}]`] =
              prompt;
          } else {
            delete converted[`rounds[${roundIndex}][prompts][${promptIndex}]`];
          }
        });
        round?.submissions?.forEach((submission, subIndex) => {
          converted[`rounds[${roundIndex}][submissions][${subIndex}][name]`] =
            submission.name;
          converted[`rounds[${roundIndex}][submissions][${subIndex}][type]`] =
            submission.type;
        });
      });
    return converted;
  }

  acceptInvitation(item) {
    const { current_grade, parent_email } = item;
    const finalObject = {
      isCategorList: true,
      name: { current_grade, parent_email },
    };
    this.commonService
      .updateRecord(`users/competitions/teams/${item.link}`, finalObject)
      .subscribe(
        (res) => {
          this.commonService.showToast('Invitation Accepted', 'success', 3000);
          const route = createRoutesForInviteTeams(item);
          this.router.navigate([route]).then(() => {
            window.location.reload();
          });
        },
        (error) => {
          this.commonService.showToast(
            error?.error?.error ?? 'Something went wrong',
            'warn',
            3000
          );
        }
      );
  }
  /**
   * Saves a reply to a question.
   *
   * @param {any} answer - The answer object.
   * @param {any} record - The record object.
   * @param {any[]} replies - The array of replies.
   * @param {number} index - The index of the current reply.
   * @param {any} loggedInUserDetail - The user details of the logged-in user.
   * @return {void}
   */
  saveReply(answer, record, replies, index, loggedInUserDetail) {
    this.commonService.saveRecord('questions/comment', record).subscribe(
      (res) => {
        this.commonService.showToast(
          'Reply saved successfully',
          'success',
          1000
        );
        answer.Comments.push({
          content: replies[index],
          answerid: answer.id,
          AnswerId: answer.id,
          createdAt: res.data.createdAt,
          User: {
            firstName: loggedInUserDetail.firstName,
            lastName: loggedInUserDetail.lastName,
            email: loggedInUserDetail.email,
          },
          id: res.data.id,
        });
        answer.replyCount = answer.Comments.length;
        replies[index] = null;
      },
      (error) => {
        this.commonService.showToast(
          error.error?.error ?? 'Something went wrong',
          'warn',
          3000
        );
      }
    );
  }

  getVideoQuestion(videoId: any): Observable<any> {
    return new Observable((observer) => {
      this.adminService
        .getVideoQuestion({ isCategorList: true, name: { data: { videoId } } })
        .subscribe(
          (item) => {
            const qIds = item.data.map((d) => d.questionId);
            const qIdsObj = qIds.join(',');
            this.getActiveQuestionsAnswersForVideo({
              data: { questionIds: qIdsObj },
            }).subscribe(
              (questions) => {
                observer.next(questions);
                observer.complete();
              },
              (err) => {
                this.handleError(
                  err,
                  'warn',
                  'Info',
                  'Error fetching questions'
                );
                observer.error(err);
              }
            );
          },
          (err) => {
            this.handleError(
              err,
              'warn',
              'Info',
              'Error fetching video questions'
            );
            observer.error(err);
          }
        );
    });
  }

  private getActiveQuestionsAnswersForVideo(data: any): Observable<any> {
    return new Observable((observer) => {
      this.adminService.getActiveQuestionsAnswersForVideo(data).subscribe(
        (item) => {
          observer.next(item.data);
          observer.complete();
        },
        (err) => {
          this.handleError(
            err,
            'warn',
            'Info',
            'Error fetching active questions'
          );
          observer.error(err);
        }
      );
    });
  }

  private handleError(
    err: any,
    type: 'error' | 'success' | 'warn' | 'info',
    summary: string,
    detail: string
  ): void {
    this.commonService.showToast(
      err?.error?.message || detail,
      type,
      3000,
      summary
    );
    console.error(err);
  }
  todayAtLastAndPreviousDay = ['', '', '', '', '', '', ''];
  getDay(index): any {
    const todayIndex = 2;
    if (index == todayIndex) {
      this.todayAtLastAndPreviousDay[6] = 'Today';
    } else {
      this.todayAtLastAndPreviousDay[index] = this.getActualDayOrder(
        todayIndex,
        index
      );
    }
    console.log(this.todayAtLastAndPreviousDay);
    return this.todayAtLastAndPreviousDay;
  }

  getActualDayOrder(todayIndex, index) {
    return this.days[todayIndex + index];
  }

  getDayNameByDate(date) {
    return this.days[new Date(date).getDay()];
  }

  getMonthByDate(date) {
    return this.months[new Date(date).getMonth()];
  }

  dates: [] = [];
  getDatesForMonth(year, month) {
    const dates = [];
    let currentDate = new Date(year, month, 1);
    while (currentDate.getMonth() === month) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }
  getMonthByNumber(month) {
    return this.months[month];
  }
  isCurrentDate(date: any): boolean {
    const today = new Date();
    const currentDate = new Date(date.year, date.month, date.day);
    return (
      currentDate.getDate() === today.getDate() &&
      currentDate.getMonth() === today.getMonth() &&
      currentDate.getFullYear() === today.getFullYear()
    );
  }
}

export const dateSequenceValidator: ValidatorFn = (
  form: FormGroup
): ValidationErrors | null => {
  // 'round_start_1',
  const dateFields = [
    'priority_registration_close',
    'regular_registration_close',
    'late_registration_close',
    'round_end_1',
    'round_start_2',
    'round_end_2',
    'round_start_3',
    'round_end_3',
  ];

  let previousDate = null;

  for (let field of dateFields) {
    const currentDateStr = form.get(field)?.value;
    if (!currentDateStr) {
      continue; // Skip if no date is set
    }

    const currentDate = parseDate(currentDateStr);
    if (previousDate && currentDate <= previousDate) {
      return {
        dateSequence: `${getNameByControlName(
          field
        )} must be after ${previousDate}`,
      };
    }
    previousDate = currentDate;
  }

  return null; // No errors
};

export function getNameByControlName(controlName: string) {
  switch (controlName) {
    case 'priority_registration_close':
      return 'Priority Registration Close Date';
    case 'regular_registration_close':
      return 'Regular Registration Close Date';
    case 'late_registration_close':
      return 'Late Registration Close Date';

    case 'round_end_1':
      return 'Round 1 End Date';
    case 'round_end_2':
      return 'Round 2 End Date';
    case 'round_end_3':
      return 'Round 3 End Date';
    default:
      return controlName;
  }
}

function parseDate(dateStr: string): Date {
  // Parse date assuming format "MM/DD/YYYY HH:MM:SS"
  if (typeof dateStr !== 'string') {
    dateStr = formatDatetoDDMMYYYY(dateStr);
  }
  let parts = dateStr.match(
    /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/
  );
  if (!parts) {
    // throw new Error("Date format is incorrect");
    parts = formatDatetoDDMMYYYY(dateStr).match(
      /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/
    );
  }
  return new Date(
    parseInt(parts[3]), // Year
    parseInt(parts[1]) - 1, // Month (Month is 0-based in JavaScript Date, hence -1)
    parseInt(parts[2]), // Day
    parseInt(parts[4]), // Hour
    parseInt(parts[5]), // Minute
    parseInt(parts[6]) // Second
  );
}

export function createRoutesForInviteTeams(item) {
  const content = item.content;
  const params = content.split('&');
  let competitionDetails: any = { competitionId: 0, teamId: 0 };
  params.forEach((param) => {
    const [key, value] = param.split('=');
    if (key === 'team_id' || key == '/acceptteaminvite?team_id') {
      const teamId = value;
      competitionDetails.teamId = parseInt(teamId);
    } else if (key === 'competition_id') {
      competitionDetails.competitionId = parseInt(value);
    }
  });
  return `/student/competitions/${competitionDetails.competitionId}/team/${competitionDetails.teamId}/team-details`;
}

export function formatDatetoDDMMYYYY(dateStr) {
  const date = new Date(dateStr);

  // Get UTC parts of the date
  const month = date.getUTCMonth() + 1; // getUTCMonth() is zero-based
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  // Pad the month, day, hours, minutes, and seconds with leading zeros if necessary
  const paddedMonth = month.toString().padStart(2, '0');
  const paddedDay = day.toString().padStart(2, '0');
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = seconds.toString().padStart(2, '0');

  // Format the date string in MM/DD/YYYY HH:MM:SS format
  return `${paddedMonth}/${paddedDay}/${year} ${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

export function defaultFormatDateStringtoDDMMYYYY(dateStr) {
  const date = new Date(dateStr);

  // Get UTC parts of the date
  const month = date.getUTCMonth() + 1; // getUTCMonth() is zero-based
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  // Pad the month, day, hours, minutes, and seconds with leading zeros if necessary
  const paddedMonth = month.toString();
  const paddedDay = day.toString();
  const paddedHours = hours.toString();
  const paddedMinutes = minutes.toString();
  const paddedSeconds = seconds.toString();

  // Format the date string in MM/DD/YYYY HH:MM:SS format
  return `${paddedMonth}/${paddedDay}/${year} ${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

export function localFormatDateStringtoDDMMYYYY(dateStr) {
  const date = new Date(dateStr);

  // Get UTC parts of the date
  const month = date.getMonth() + 1; // getUTCMonth() is zero-based
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Pad the month, day, hours, minutes, and seconds with leading zeros if necessary
  const paddedMonth = month.toString();
  const paddedDay = day.toString();
  const paddedHours = hours.toString();
  const paddedMinutes = minutes.toString();
  const paddedSeconds = seconds.toString();

  // Format the date string in MM/DD/YYYY HH:MM:SS format
  return `${paddedMonth}/${paddedDay}/${year} ${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

export function localFormatDateToMMMDDYYYY(dateStr: string): string {
  const date = new Date(dateStr);

  // Get parts of the date
  const day = date.getDate(); // Get day of the month
  const year = date.getFullYear(); // Get the full year

  // Get the month abbreviation using toLocaleString without using an array
  const month = date.toLocaleString('default', { month: 'short' });

  // Format the date string in "MMM d, y" format
  return `${month} ${day}, ${year}`; // e.g., 'Sep 24, 2024'
}
