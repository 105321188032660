<div class="d-flex flex-column h-100 overflow-hidden">
  <ng-container *ngIf="config.data?.customHeader">
    <div class="header-content">
      <figure class="d-flex align-items-center gap-2 m-0">
        <img
          src="../../../../assets/competitions/alert.png"
          class="img-fluid"
          style="width: 24px"
          alt="Alert"
        />
        <h6 class="m-0 fs-6">Action Required!</h6>
      </figure>
      <button
        class="btn close-btn d-flex justify-content-center align-items-center"
        (click)="ref.close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </ng-container>

  <div
    class="competition-payment-content flex-grow-1 overflow-vertical d-flex flex-column gap-3 p-4"
  >
    <div
      class="card"
      *ngFor="let competition of competitionWithPendingPayments; else: noData"
    >
      <div class="card-body">
        <div class="d-flex gap-3 mb-3">
          <figure class="competition-img-wrapper m-0">
            <img
              [src]="imageBaseURL + 'common/getfile/' + competition?.thumbnail"
              alt="Certification Trophy Image"
            />
          </figure>
          <div class="card-title mb-0 mt-2">
            <h6 class="m-0 fs-6">{{ competition?.name }}</h6>
          </div>
        </div>

        <!-- Payment Required -->
        <div
          class="d-flex justify-content-between align-items-center gap-3 flex-wrap"
        >
          <div class="d-flex align-items-center gap-2">
            <img
              style="width: 24px"
              class="status-img"
              src="../../../../assets/competitions/exclamatory.png"
              alt="Incomplete"
            />
            <span>
              <h6 class="m-0 fs-6">Payment Required</h6>
            </span>
          </div>

          <button class="btn btn-primary" (click)="payNow(competition)">
            Pay Now
          </button>
        </div>
      </div>
    </div>

    <ng-template>
      <div class="card p-3 text-center">No data available!</div>
    </ng-template>
  </div>
</div>
