<div>
  <span
    class="search-container p-input-icon-left w-100"
    [ngStyle]="{
      'max-width': this.searchMaxWidth ? this.searchMaxWidth : '300px'
    }"
  >
    <!-- <i class="pi pi-search"></i> -->
    <img
      class="search-icon"
      src="../../../../assets/icons/search-normal.svg"
      alt="Search"
    />
    <input
      type="text"
      pInputText
      [placeholder]="placeholder"
      [showClear]="true"
      [(ngModel)]="searchText"
      (ngModelChange)="onTextSearched()"
    />
    <button
      *ngIf="isFilterButtonVisible"
      (click)="openFilterModal($event)"
      class="filter-btn"
    >
      <span class="icon icon-filter"> </span>
    </button>
  </span>
</div>
