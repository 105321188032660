import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonServiceService } from '../shared/common-service.service';

@Component({
  selector: 'app-final-submission-dialog',
  templateUrl: './final-submission-dialog.component.html',
  styleUrls: ['./final-submission-dialog.component.scss'],
})
export class FinalSubmissionDialogComponent {

  isIndividual = false;

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private commonService: CommonServiceService
  ) {}

  ngOnInit() {
    console.log(this.config.data?.submissionDetails);
    this.isIndividual = this.config.data?.competitionDetails?.individual;
  }

  cancel() {
    this.ref.close(false);
  }

  confirmAndSubmit() {
    const data = this.config.data?.submissionDetails;
    data &&
      data?.forEach((element) => {
        element?.id &&
          this.commonService
            .updateRecord(
              `users/competitions/teams/submissions/${element.id}`,
              {}
            )
            .subscribe({
              next: (res) => {
                this.ref.close(true);
              },
              error: (error) => {
                this.ref.close(false);
              },
            });
      });
  }
}
