import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TreeModule } from 'primeng/tree';

import { NgOtpInputModule } from 'ng-otp-input';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DragDropModule } from 'primeng/dragdrop';
import { EditorModule } from 'primeng/editor';
import { InputNumberModule } from 'primeng/inputnumber';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { DocFileUploadComponent } from '../admin/doc-file-upload/doc-file-upload.component';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { OrCreateAndGroupListComponent } from '../organisation/components/or-create-and-group-list/or-create-and-group-list.component';
import { OrInviteStudentComponent } from '../organisation/components/or-invite-student/or-invite-student.component';
import { OrStudentDetailsComponent } from '../organisation/components/or-student-details/or-student-details.component';
import { StudentListComponent } from '../organisation/components/student-list/student-list.component';
import { DateHighlightPipe } from '../organisation/pipe/date-highlight.pipe';
import { GetdatePipe } from '../organisation/pipe/getdate.pipe';
import { CoDetailCardComponent } from '../student-competitions/components/co-detail-card/co-detail-card.component';
import { CountdownTimerComponent } from '../student-competitions/components/countdown-timer/countdown-timer.component';
import { MyCompetitionsComponent } from '../student-competitions/components/my-competitions/my-competitions.component';
import { StartupComponent } from '../student-competitions/components/startup/startup.component';
import { CalculatePercentagePipe } from '../student-competitions/pipe/calculate-percentage.pipe';
import { GetCurrentRoundPipe } from '../student-competitions/pipe/get-current-round.pipe';
import { GetFullysubmittedPipe } from '../student-competitions/pipe/get-fullysubmitted.pipe';
import { GetPreviousRoundDetailsPipe } from '../student-competitions/pipe/get-previous-round-details.pipe';
import { IsCurrentRoundSubmittedPipe } from '../student-competitions/pipe/is-current-round-submitted.pipe';
import { RegistrationCheckPipe } from '../student-competitions/pipe/registration-check.pipe';
import { RoundPercentagePipe } from '../student-competitions/pipe/round-percentage.pipe';
import { TestPartialSelectPipe } from '../student-competitions/pipe/test-partial-select.pipe';
import { SideMenuComponent } from '../students/admission/side-menu/side-menu.component';
import { ChangePasswordComponent } from '../students/change-password/change-password.component';
import { ManageSubmissionComponent } from '../students/doc-review/manage-submission/manage-submission.component';
import { HeadersComponent } from '../students/headers/headers.component';
import { LoginComponent } from '../students/login/login.component';
import { PurchasePaymentComponent } from '../students/purchase-payment/purchase-payment.component';
import { SignUpComponent } from '../students/sign-up/sign-up.component';
import { CareerInterestComponent } from '../students/student-profile/career-interest/career-interest.component';
import { PersonalityTestComponent } from '../students/student-profile/personality-test/personality-test.component';
import { StandardizedTestComponent } from '../students/student-profile/standardized-test/standardized-test.component';
import { StudentProfileComponent } from '../students/student-profile/student-profile.component';
import { UniversitySubjectInterestComponent } from '../students/student-profile/university-subject-interest/university-subject-interest.component';
import { StudentSignupComponent } from '../students/student-signup/student-signup.component';
import { AlgoHeaderComponent } from './components/algo-header/algo-header.component';
import { AlgoedGraphComponent } from './components/algoed-graph/algoed-graph.component';
import { CertificateShareCardComponent } from './components/certificate-share-card/certificate-share-card.component';
import { DownloadAwardCardComponent } from './components/download-award-card/download-award-card.component';
import { CustomFileUploadComponent } from './components/file-upload/custom-file-upload.component';
import { ForumDetailComponent } from './components/forum-detail/forum-detail.component';
import { ForumListComponent } from './components/forum-list/forum-list.component';
import { GradeAndparentEmailComponent } from './components/grade-andparent-email/grade-andparent-email.component';
import { MediaDetailsComponent } from './components/media-details/media-details.component';
import { NonUserFooterComponent } from './components/non-user-footer/non-user-footer.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { OrInviteSignupComponent } from './components/or-invite-signup/or-invite-signup.component';
import { PaymentResultComponent } from './components/payment-result/payment-result.component';
import { PublicForumComponent } from './components/public-forum/public-forum.component';
import { SearchComponent } from './components/search/search.component';
import { SharableLinkComponent } from './components/sharable-link/sharable-link.component';
import { SharePageCardComponent } from './components/share-page-card/share-page-card.component';
import { TagFormComponent } from './components/tag-form/tag-form.component';
import { TagsListComponent } from './components/tags-list/tags-list.component';
import { TeamMemberListComponent } from './components/team-member-list/team-member-list.component';
import { TopRecordsGraphComponent } from './components/top-records-graph/top-records-graph.component';
import { DynamicStyleDirective } from './directives/dynamic-style.directive';
import { MaxWordsDirective } from './directives/max-words.directive';
import { FormatFileSizePipe } from './fileSize.pipe';
import { FilterTaskForCalenderPipe } from './filter-task-for-calender.pipe';
import { GlobalSearchPipePipe } from './global-search.pipe';
import { ListColorPipe } from './list-color.pipe';
import { CompetitionStagePipe } from './pipe/competition-stage.pipe';
import { RenderContentPipe } from './pipe/render-content.pipe';
import { SafeUrlPipe } from './pipe/safe-url.pipe';
import { SortByDatePipe } from './pipe/sort-by-date.pipe';
import { SortCategoryListPipe } from './pipe/sort-category-list.pipe';
import { SortCompetitionsPipe } from './pipe/sort-competitions.pipe';
import { TimeFormatPipe } from './pipe/time-format.pipe';
import { TimeZoneDatePipe } from './pipe/time-zone-date.pipe';
import { WordCountPipe } from './pipe/word-count.pipe';
import { CreateArrayusingTotalsPipe } from '../judges/pipes/create-arrayusing-totals.pipe';
import { RegistrationStagesComponent } from '../student-competitions/components/registration-stages/registration-stages.component';
import { CompetitionDetailHeaderComponent } from './components/competition-detail-header/competition-detail-header.component';
import { PendingCountPipe } from '../student-competitions/pipe/pending-count.pipe';
import { PendingMultiplePaymentModalComponent } from './components/pending-multiple-payment-modal/pending-multiple-payment-modal.component';
@NgModule({
  declarations: [
    CalculatePercentagePipe,
    OrCreateAndGroupListComponent,
    SignUpComponent,
    StudentListComponent,
    LoginComponent,
    HeadersComponent,
    GlobalSearchPipePipe,
    FileUploadComponent,
    PersonalityTestComponent,
    SideMenuComponent,
    CoDetailCardComponent,
    StandardizedTestComponent,
    CareerInterestComponent,
    FormatFileSizePipe,
    UniversitySubjectInterestComponent,
    SortCategoryListPipe,
    ChangePasswordComponent,
    DateHighlightPipe,
    GetdatePipe,
    FilterTaskForCalenderPipe,
    ListColorPipe,
    ForumDetailComponent,
    ForumListComponent,
    RenderContentPipe,
    TagsListComponent,
    TagFormComponent,
    PublicForumComponent,
    SharableLinkComponent,
    MediaDetailsComponent,
    SearchComponent,
    SafeUrlPipe,
    PaymentResultComponent,
    DynamicStyleDirective,
    AlgoHeaderComponent,
    NotFoundPageComponent,
    TopRecordsGraphComponent,
    AlgoedGraphComponent,
    SortByDatePipe,
    NonUserFooterComponent,
    OrInviteSignupComponent,
    OrInviteStudentComponent,
    CompetitionStagePipe,
    OrStudentDetailsComponent,
    PurchasePaymentComponent,
    TeamMemberListComponent,
    CountdownTimerComponent,
    StudentProfileComponent,
    ManageSubmissionComponent,
    StartupComponent,
    MyCompetitionsComponent,
    CustomFileUploadComponent,
    TimeFormatPipe,
    SharePageCardComponent,
    StudentSignupComponent,
    DocFileUploadComponent,
    WordCountPipe,
    MaxWordsDirective,
    GradeAndparentEmailComponent,
    SortCompetitionsPipe,
    RegistrationCheckPipe,
    IsCurrentRoundSubmittedPipe,
    GetFullysubmittedPipe,
    GetCurrentRoundPipe,
    TestPartialSelectPipe,
    DownloadAwardCardComponent,
    CertificateShareCardComponent,
    RoundPercentagePipe,
    GetPreviousRoundDetailsPipe,
    TimeZoneDatePipe,
    PendingCountPipe,
    RegistrationStagesComponent,
    CompetitionDetailHeaderComponent,
    PendingMultiplePaymentModalComponent,
  ],
  imports: [
    TagModule,
    CommonModule,
    MultiSelectModule,
    PanelMenuModule,
    CheckboxModule,
    KeyFilterModule,
    InputTextModule,
    InputSwitchModule,
    CardModule,
    ButtonModule,
    TableModule,
    SidebarModule,
    DialogModule,
    TreeModule,
    DropdownModule,
    DividerModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
    FileUploadModule,
    MenuModule,
    ToastModule,
    RadioButtonModule,
    TabMenuModule,
    SelectButtonModule,
    DragDropModule,
    MenubarModule,
    ProgressBarModule,
    MultiSelectModule,
    AccordionModule,
    ChartModule,
    ToggleButtonModule,
    EditorModule,
    PaginatorModule,
    TabViewModule,
    NgxDocViewerModule,
    StepsModule,
    ListboxModule,
    PanelModule,
    CarouselModule,
    BadgeModule,
    SplitButtonModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    DropdownModule,
    ChipModule,
    ToggleButtonModule,
    RippleModule,
    ScrollPanelModule,
    BadgeModule,
    DividerModule,
    SplitButtonModule,
    AccordionModule,
    SplitButtonModule,
    OverlayPanelModule,
    BadgeModule,
    ListboxModule,
    InfiniteScrollModule,
    NgOptimizedImage,
    ProgressSpinnerModule,
    InputNumberModule,
    NgOtpInputModule,
    ChipsModule,
    CreateArrayusingTotalsPipe,
  ],
  exports: [
    WordCountPipe,
    TimeZoneDatePipe,
    TimeFormatPipe,
    StudentSignupComponent,
    CustomFileUploadComponent,
    CoDetailCardComponent,
    StudentProfileComponent,
    ManageSubmissionComponent,
    CountdownTimerComponent,
    RegistrationCheckPipe,
    IsCurrentRoundSubmittedPipe,
    MyCompetitionsComponent,
    StartupComponent,
    TagModule,
    PurchasePaymentComponent,
    OrStudentDetailsComponent,
    CompetitionStagePipe,
    CalculatePercentagePipe,
    OrInviteSignupComponent,
    StudentListComponent,
    OrCreateAndGroupListComponent,
    StepsModule,
    OrInviteStudentComponent,
    CheckboxModule,
    KeyFilterModule,
    InputTextModule,
    ConfirmDialogModule,
    InputSwitchModule,
    CardModule,
    ButtonModule,
    TableModule,
    SidebarModule,
    DialogModule,
    TreeModule,
    DropdownModule,
    DividerModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
    FileUploadModule,
    MenuModule,
    ToastModule,
    RadioButtonModule,
    TabMenuModule,
    SelectButtonModule,
    DragDropModule,
    MenubarModule,
    ProgressBarModule,
    MultiSelectModule,
    AccordionModule,
    InputNumberModule,
    ChartModule,
    ToggleButtonModule,
    EditorModule,
    PaginatorModule,
    // GoogleChartsModule,
    SideMenuComponent,
    SignUpComponent,
    LoginComponent,
    HeadersComponent,
    TabViewModule,
    GlobalSearchPipePipe,
    FileUploadComponent,
    // AcademicRecordComponent,
    StandardizedTestComponent,
    CareerInterestComponent,
    FormatFileSizePipe,
    NgxDocViewerModule,
    UniversitySubjectInterestComponent,
    PanelModule,
    CarouselModule,
    BadgeModule,
    SortCategoryListPipe,
    ListboxModule,
    PanelMenuModule,
    SplitButtonModule,
    OverlayPanelModule,
    ChipModule,
    DropdownModule,
    ChangePasswordComponent,
    ToggleButtonModule,
    RippleModule,
    ScrollPanelModule,
    BadgeModule,
    DividerModule,
    DateHighlightPipe,
    GetdatePipe,
    FilterTaskForCalenderPipe,
    ListColorPipe,
    SplitButtonModule,
    AccordionModule,
    ForumDetailComponent,
    ForumListComponent,
    SplitButtonModule,
    BadgeModule,
    ListboxModule,
    TagsListComponent,
    InfiniteScrollModule,
    SharableLinkComponent,
    MediaDetailsComponent,
    ProgressSpinnerModule,
    SearchComponent,
    DynamicStyleDirective,
    AlgoHeaderComponent,
    SafeUrlPipe,
    NotFoundPageComponent,
    TopRecordsGraphComponent,
    AlgoedGraphComponent,
    RenderContentPipe,
    SortByDatePipe,
    NonUserFooterComponent,
    PersonalityTestComponent,
    SharePageCardComponent,
    DocFileUploadComponent,
    NgOptimizedImage,
    MaxWordsDirective,
    GetFullysubmittedPipe,
    GetCurrentRoundPipe,
    TestPartialSelectPipe,
    DownloadAwardCardComponent,
    CertificateShareCardComponent,
    RoundPercentagePipe,
    GetPreviousRoundDetailsPipe,
    ChipsModule,
    SortCompetitionsPipe,
    CreateArrayusingTotalsPipe,
    RegistrationStagesComponent,
    CompetitionDetailHeaderComponent,
    PendingCountPipe,
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor() {}
}
