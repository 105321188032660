<div
  class="competition-cards rounded individual"
>
  <div class="card border-0 rounded">
    <div class="card-body">
      <div class="d-flex gap-3 pb-3 pb-lg-0">
        <figure class="card-figure m-0">
          <img
            class="img-fluid"
            [width]="320"
            [height]="180"
            [src]="
              competitionDetails.thumbnail
                ? imageBaseURL +
                  'common/getfile/' +
                  competitionDetails.thumbnail
                : '../../../../assets/images/student-portal/competition-img.png'
            "
            [alt]="competitionDetails?.name ?? 'Competition Image'"
          />
        </figure>
        <div class="flex-grow-1 overflow-hidden">
          <h6 class="card-title mt-0 text-break">
            {{ competitionDetails.name }}
          </h6>

          <!-- timer section -->
          <div class="mt-2">
            <!-- ENDS IN -->
            <div class="d-flex align-items-center gap-2">
              <span>
                <svg
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.01331 0.164062C4.88768 0.164063 4.76719 0.21397 4.67835 0.302806C4.58952 0.391642 4.53961 0.51213 4.53961 0.637764C4.53961 0.763397 4.58952 0.883885 4.67835 0.972721C4.76719 1.06156 4.88768 1.11146 5.01331 1.11146H7.85552C7.98115 1.11146 8.10164 1.06156 8.19047 0.972721C8.27931 0.883885 8.32922 0.763397 8.32922 0.637764C8.32922 0.51213 8.27931 0.391642 8.19047 0.302806C8.10164 0.21397 7.98115 0.164063 7.85552 0.164062H5.01331ZM12.1188 7.74328C12.1188 10.8825 9.57363 13.4277 6.43441 13.4277C3.2952 13.4277 0.75 10.8825 0.75 7.74328C0.75 4.60406 3.2952 2.05887 6.43441 2.05887C9.57363 2.05887 12.1188 4.60406 12.1188 7.74328ZM11.1714 7.74328C11.1714 5.12703 9.05066 3.00627 6.43441 3.00627C3.81816 3.00627 1.6974 5.12703 1.6974 7.74328C1.6974 10.3595 3.81816 12.4803 6.43441 12.4803C9.05066 12.4803 11.1714 10.3595 11.1714 7.74328ZM11.5068 2.19766C11.4631 2.15242 11.4108 2.11633 11.353 2.0915C11.2952 2.06668 11.2331 2.05361 11.1702 2.05306C11.1073 2.05252 11.0449 2.0645 10.9867 2.08832C10.9285 2.11214 10.8756 2.14731 10.8311 2.19179C10.7866 2.23627 10.7515 2.28916 10.7277 2.34737C10.7038 2.40559 10.6918 2.46797 10.6924 2.53086C10.6929 2.59376 10.706 2.65592 10.7308 2.71371C10.7557 2.77151 10.7917 2.82378 10.837 2.86747L11.7844 3.81488C11.8284 3.85886 11.8806 3.89374 11.9381 3.91753C11.9956 3.94132 12.0572 3.95355 12.1195 3.95353C12.1817 3.95351 12.2433 3.94123 12.3008 3.9174C12.3582 3.89357 12.4105 3.85865 12.4544 3.81464C12.4984 3.77063 12.5333 3.71838 12.5571 3.66089C12.5809 3.6034 12.5931 3.54179 12.5931 3.47957C12.5931 3.41735 12.5808 3.35574 12.557 3.29826C12.5331 3.24079 12.4982 3.18857 12.4542 3.14459L11.5068 2.19766Z"
                    fill="#595959"
                  />
                  <path
                    d="M6.2001 11.5323C7.26799 11.5323 8.29213 11.1331 9.04724 10.4224C9.80235 9.7117 10.2266 8.7478 10.2266 7.74273C10.2266 6.73767 9.80235 5.77376 9.04724 5.06308C8.29213 4.35239 7.26799 3.95312 6.2001 3.95313V7.74273V11.5323Z"
                    fill="#0CBD80"
                  />
                </svg>
              </span>
              <span class="text-endsin"> STARTS IN </span>
            </div>
            <!-- ENDS IN -->

            <!-- timer -->
            <div class="d-flex flex-wrap timer-joiner-section">
              <app-countdown-timer
                [endTime]="competitionDetails?.status_time_update"
              ></app-countdown-timer>
              <!-- End: timer section -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End: competition cards -->
  </div>
</div>
